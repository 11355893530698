import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  PlayerCreate,
  PlayerPosition,
  PlayerSearchElement,
  PlayerSquadElement
} from "../../client/api";
import { ClavaSelectItems, IDType } from "../../config/types";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { showTranslated, translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import ClavaButton from "../../components/Atoms/ClavaButton";
import client from "../../client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClavaImage from "../../components/Atoms/ClavaImage";
import { getMainLeague } from "../../config/utils";
import ClavaCheckbox from "../../components/Atoms/ClavaCheckbox";

const positionCheck: AdditionalCheck<PlayerCreate, "teamPositions"> = (a, b) => a.length === b.length;
const player: PlayerCreate = {
  familyName: "",
  givenName: "",
  birthdate: null,
  foot: null,
  height: null,
  externalId: null,
  mainTeamId: -1,
  jersey: null,
  teamPositions: []
};
const TeamEditPlayer: React.FC<{
  onCancel: () => void;
  onAdd: (player: PlayerSquadElement) => void,
  teamId: IDType
}> = ({
        teamId,
        onAdd, onCancel
      }) => {
  const { l, filterAoi } = useContext(ClavaContext);
  const { loading, call } = useServer(false);
  const [players, setPlayers] = useState<PlayerSearchElement[]>([]);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerSearchElement>();
  const [positions, setPositions] = useState<PlayerPosition[]>([]);
  const form = useFormData(player,
    ["givenName", "familyName"],
    {
      teamPositions: positionCheck
    },
    ["givenName", "familyName"],
    [],
    [], [], [], ["givenName", "familyName", "teamPositions"]);
  useEffect(() => {
    call("getPLayerPositions", []).then(setPositions);
  }, [call]);
  const positionItems = useMemo<ClavaSelectItems[]>(
    () => positions.map(p => ({
      key: p.key,
      label: showTranslated(p.translation, l)
    })),
    [l, positions]
  );
  const onChangePos = useCallback((val: string | undefined) => {
    const foundPos = positions.find(p => p.key === val);
    if (foundPos)
      form.setFormData(fd => ({
        ...fd,
        teamPositions: [{
          teamId,
          position: foundPos.key
        }]
      }));
  }, [positions, form, teamId]);
  const onSave = useCallback(() => {
    if (form.allChanged) {
      call("createPlayer", [teamId, {
        givenName: form.givenName,
        familyName: form.familyName,
        teamPositions: form.teamPositions,
        mainTeamId: teamId
      }], "saveFailed", "saveSuccess").then((pl) => {
        onAdd(pl.player);
      });
    }
  }, [call, form.givenName, form.familyName, form.teamPositions, onAdd, form.allChanged, teamId]);
  const onTransfer = useCallback(() => {
    if (selectedPlayer && selectedPlayer.mainTeam && form.teamPositions.length) {
      call("transferPlayerToTeam", [selectedPlayer.id, selectedPlayer.mainTeam.id, teamId, form.teamPositions[0].position], "saveFailed", "saveSuccess").then((pl) => {
        onAdd(pl.player);
      });
    }
  }, [call, selectedPlayer, form.teamPositions, onAdd, teamId]);
  const onSaveBoth = useCallback(() => {
    if (selectedPlayer && form.teamPositions.length) {
      call("addPlayerToTeam", [selectedPlayer.id, teamId, form.teamPositions[0].position], "saveFailed", "saveSuccess").then((pl) => {
        onAdd(pl.player);
      });
    }
  }, [selectedPlayer, call, form.teamPositions, onAdd, teamId]);
  useEffect(() => {
    if (form.givenName.length !== 0 || form.familyName.length !== 0) {
      const timeo = setTimeout(() => {
        client().searchPlayer(form.givenName, form.familyName, filterAoi).then(setPlayers);
      }, 300);
      return () => {
        window.clearTimeout(timeo);
      };
    } else {
      setPlayers([]);
    }
  }, [form.givenName, form.familyName, filterAoi]);
  return (
    <LoadingBlock isLoading={loading}>
      <ItemBlockHeader
        image={selectedPlayer && selectedPlayer.photo ? selectedPlayer.photo : faUserCircle}
        name={`${player.givenName} ${player.familyName}`}
        imageRounded>
        <ClavaButton onClick={selectedPlayer ? onTransfer : onSave}
                     disabled={!((form.allChanged && !selectedPlayer) || (!!selectedPlayer && !!selectedPlayer.mainTeam && form.teamPositions.length !== 0))}
                     className="my-2">
          {translate(selectedPlayer ? "transfer" : "save", l)}
        </ClavaButton>
        {!!selectedPlayer && (
          <ClavaButton onClick={onSaveBoth} disabled={form.teamPositions.length === 0}
                       className="my-2">
            {translate("bothTeams", l)}
          </ClavaButton>
        )}
        <ClavaButton onClick={onCancel} className="my-2" secondary>
          {translate("cancel", l)}
        </ClavaButton>
      </ItemBlockHeader>
      <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
        <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeGivenName}
                        changed={form.givenNameChanged}
                        value={selectedPlayer ? selectedPlayer.givenName : form.givenName}
                        label={translate("givenName", l)} />
        <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeFamilyName}
                        changed={form.familyNameChanged}
                        value={selectedPlayer ? selectedPlayer.familyName : form.familyName}
                        label={translate("familyName", l)} />
      </div>
      {players.length !== 0 && (
        <div className="my-4">
          <h4 className="font-bold">{translate("foundPlayers", l)}</h4>
          <div className="flex flex-col items-stretch justify-start">
            {players.map((p) => {
              const t = p.mainTeam;
              const lea = t ? getMainLeague(t.leagues) : undefined;
              return (
                <div key={"found-player-" + p.id}
                     className="border-b border-b-light-gray dark:border-b-light-gray-dark p-2 flex flex-row items-center justify-start">
                  <div className="w-10 h-10 rounded-full overflow-hidden">
                    {!!p.photo ? (
                      <ClavaImage width={"100%"} image={p.photo} />
                    ) : (
                      <FontAwesomeIcon icon={faUserCircle} className="w-10 h-10" />
                    )}
                  </div>
                  <div className="flex-1 ml-2 flex flex-col items-start justify-evenly">
                    <span className="font-semibold">{p.givenName} {p.familyName}</span>
                    {!!t && (
                      <span>{showTranslated(t.name, l)}{lea ? ` | ${showTranslated(lea.name, l)}` : ""}</span>
                    )}
                  </div>
                  <ClavaCheckbox onClick={(val: boolean) => {
                    if (val) {
                      setSelectedPlayer(p);
                    } else {
                      setSelectedPlayer(undefined);
                    }
                  }} checked={selectedPlayer?.id === p.id} />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="my-4">
        <ClavaSelect uniqueId={"player-pos"} items={positionItems} onChange={onChangePos}
                     changed={form.teamPositionsChanged} label={translate("position", l)}
                     value={form.teamPositions.length ? form.teamPositions[0].position : undefined} />
      </div>
    </LoadingBlock>
  );
};

export default TeamEditPlayer;
