/* eslint-disable no-console */
import React from "react";
import * as tus from "tus-js-client";
import axios from "axios";
import { Image as ApiImage, MediaCrop, Video } from "../client/api";
import { ClavaFile, CloudflareImage, LogMessage } from "./types";
import client from "../client";
import { errorLogging, isLocalDev } from "./utils";
import { addLog } from "./logger";

export function isVideoType(file: ApiImage | Video): file is Video {
  return "hls" in file;
}

export function isPhotoType(file: ApiImage | Video): file is ApiImage {
  return !isVideoType(file);
}

export function mapToImage(i: CloudflareImage, d: MediaCrop | undefined): ApiImage {
  return {
    id: i.id,
    aspectRatio: i.aspectRatio,
    url: i.path,
    uploadDate: "",
    cropAspectRatio: d?.cropAspectRatio,
    cropScale: d?.cropScale,
    cropX: d?.cropX,
    cropY: d?.cropY
  };
}

export function mapToVideo(i: CloudflareImage, d: MediaCrop | undefined): Video {
  return {
    id: i.id,
    aspectRatio: i.aspectRatio,
    dash: i.path,
    hls: i.path,
    uploadDate: "",
    cropAspectRatio: d?.cropAspectRatio,
    cropScale: d?.cropScale,
    cropX: d?.cropX,
    cropY: d?.cropY
  };
}

export function mapToImageArray(
  medias: CloudflareImage[],
  cropData: MediaCrop[]
): ApiImage[] {
  return medias.map((i, idx) => {
    return mapToImage(i, cropData[idx]);
  });
}

export function mapToVideoArray(
  medias: CloudflareImage[],
  cropData: MediaCrop[]
): Video[] {
  return medias.map((i, idx) => {
    return mapToVideo(i, cropData[idx]);
  });
}

export function mapToVideoImageArray(
  medias: CloudflareImage[],
  cropData: MediaCrop[]
): (ApiImage | Video)[] {
  const key = "mdp";
  return medias.map((i, idx) => {
    if (i.path.includes(key)) {
      return mapToVideo(i, cropData[idx]);
    }
    return mapToImage(i, cropData[idx]);
  });
}

export function calcMediaStyle<T extends MediaCrop | ApiImage | Video>(
  width: number,
  originalAs: number,
  data: T
): React.CSSProperties {
  const scale = data.cropScale ?? 1;
  const top = data.cropY ? data.cropY * 100 : 0;
  const left = data.cropX ? data.cropX * 100 : 0;
  return {
    position: "absolute",
    width: width * scale,
    height: (width / originalAs) * scale,
    top: `${top}%`,
    left: `${left}%`
  };
}

const alreadyUploaded: {
  success: { id: string; result: CloudflareImage }[];
} = {
  success: []
};

export function uploadCloudflareVideo(
  file: ClavaFile,
  crop: MediaCrop,
  progress?: (sent: number, total: number) => void
) {
  return new Promise<CloudflareImage>((resolve, reject) => {
    let link = "";
    let id = "";
    client()
      .maxVideoLength()
      .then((maxLength) => {

        if (file.file.size > maxLength) { // TODO size length video
          reject(`error_length_${maxLength}`);
          return;
        }
        const name = file.filename;
        const aspectRatio = file.as;
        client()
          .tusVideoLink(aspectRatio, file.file.size, name, crop)
          .then((res) => {
            if (isLocalDev()) {
              console.log("\x1b[34m%s\x1b[0m", `Response: ${res}`);
            }
            if (res) {
              link = res.url;
              id = res.id;
            } else {
              if (isLocalDev()) {
                console.log("\x1b[34m%s\x1b[0m", `Error: ${res}`);
              }
              reject("error");
              return;
            }
            if (link !== "") {
              const msg: LogMessage = { Post: link, Body: file.file };
              if (isLocalDev()) {
                console.log("\x1b[34m%s\x1b[0m", `POST: ${link}`);
              }

              addLog("req", msg);
              if (isLocalDev()) {
                console.log("\x1b[34m%s%O\x1b[0m", "upload Body:", file.file);
              }
              const upload = new tus.Upload(file.file, {
                endpoint: link, // use your tus server endpoint instead
                retryDelays: [0, 1000, 3000, 5000],
                onError: (err) => {
                  const msg2: LogMessage = {
                    Response: "Cloudflare",
                    Body: err
                  };
                  addLog("response", msg2);
                  if (isLocalDev()) {
                    console.log("\x1b[34m%s%O\x1b[0m", "upload Response:", err);
                  }
                  reject(err);
                },
                onSuccess: () => {
                  const result: CloudflareImage = {
                    id,
                    variants: [],
                    path: `https://customer-g5i9dzutjuero5qy.cloudflarestream.com/${id}/manifest/video.mdp`,
                    aspectRatio
                  };
                  alreadyUploaded.success.push({ id: file.url, result });
                  resolve(result);
                },
                onProgress: (uploaded, total) => {
                  if (progress) {
                    progress(uploaded, total);
                  }
                }
              });
              upload.start();
            } else {
              reject("error");
            }
          });
      });
  });
}

export function uploadCloudflareImage(
  file: ClavaFile,
  crop: MediaCrop,
  progress?: (sent: number, total: number) => void
) {
  return new Promise<CloudflareImage>((resolve, reject) => {
    let link = "";
    let id = "";
    const aspectRatio = file.as;
    client()
      .imageLink(aspectRatio, crop)
      .then((res) => {
        if (res) {
          link = res.url;
          id = res.id;
        } else {
          errorLogging(res);
          reject("error");
          return;
        }
        if (link !== "") {
          const body = new FormData();

          body.append("file", file.file);
          const msg: LogMessage = { Post: link, Body: body };
          if (isLocalDev()) {
            console.log("\x1b[34m%s\x1b[0m", `POST: ${link}`);
          }

          addLog("req", msg);
          if (isLocalDev()) {
            console.log("\x1b[34m%s%O\x1b[0m", "upload Body:", body);
          }
          axios
            .post(link, body, {
              headers: {
                "Content-Type": "multipart/form-data"
              },
              onUploadProgress: (progressEvent) => {
                const total = progressEvent.total ?? 1;
                const send = (progressEvent.progress ?? 0) * total;
                if (progress) {
                  progress(send, total);
                }
                if (isLocalDev()) {
                  console.log(`Send: ${send} Total: ${total}`);
                }
              }
            })
            .then(
              (response) => {
                const result = response.data;
                const msg2: LogMessage = {
                  Response: "Cloudflare Response",
                  Body: result
                };
                addLog("response", msg2);
                if (isLocalDev()) {
                  console.log("\x1b[34m%s%O\x1b[0m", "upload Result:", result);
                }
                if (result.success) {
                  const cloudflareResult: CloudflareImage = {
                    ...result.result,
                    id,
                    aspectRatio,
                    path:
                      result.result.variants.find(
                        (f: string) => f.indexOf("public") !== -1
                      ) ?? result.result.variants[0]
                  };
                  alreadyUploaded.success.push({
                    id: file.url,
                    result: cloudflareResult
                  });
                  resolve(cloudflareResult);
                } else {
                  const msg3: LogMessage = {
                    Response: "Cloudflare Response",
                    Body: response
                  };
                  addLog("response", msg3);
                  if (isLocalDev()) {
                    console.log(
                      "\x1b[34m%s%O\x1b[0m",
                      "upload Response:",
                      response
                    );
                  }
                  reject(response);
                }
              },
              (err) => {
                const msg2: LogMessage = {
                  Response: "Cloudflare",
                  Body: err
                };
                addLog("response", msg2);
                if (isLocalDev()) {
                  console.log("\x1b[34m%s%O\x1b[0m", "upload Response:", err);
                }
                if ("response" in err)
                  reject(err.response.data);
                else reject(err);
              }
            ).catch((err) => {

            const msg2: LogMessage = {
              Response: "Cloudflare",
              Body: err
            };
            addLog("response", msg2);
            if (isLocalDev()) {
              console.log("\x1b[34m%s%O\x1b[0m", "upload Response:", err);
            }
            if ("response" in err)
              reject(err.response.data);
            else
              reject(err);
          });
        } else {
          reject("error");
        }
      });
  });
}

export async function uploadCloudflare(
  file: ClavaFile,
  crop: MediaCrop,
  progress?: (sent: number, total: number) => void
) {
  const alr = alreadyUploaded.success.find((s) => s.id === file.url);
  if (alr) {
    return new Promise<CloudflareImage>((resolve) => {
      resolve(alr.result);
    });
  }
  if (file.filename.startsWith("video")) {
    return uploadCloudflareVideo(file, crop, progress);
  }
  return uploadCloudflareImage(file, crop, progress);
}

export function getFileSize(blob: Uint8Array, timeout = 10000) {
  return new Promise<{ w: number, h: number }>((resolve, reject) => {
    const img = new Image();
    const res = setTimeout(() => {
      reject("Loading Image timed out");
    }, timeout);
    img.onload = () => {
      clearTimeout(res);
      resolve({ w: img.width, h: img.height });
    };
    img.src = URL.createObjectURL(new Blob([blob], { type: "image/avif" }));

  });
}

export function getFileSizeVideo(url: string, timeout = 10000) {
  return new Promise<{ w: number, h: number }>((resolve, reject) => {
    const video = document.createElement("video");
    const res = setTimeout(() => {
      reject("Loading Video timed out");
    }, timeout);
    video.onloadedmetadata = () => {
      clearTimeout(res);
      URL.revokeObjectURL(url);
      console.log(video);
      resolve({ w: video.videoWidth, h: video.videoHeight });
    };
    video.src = url;
    video.load();
  });

}
