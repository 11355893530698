/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogResponse } from '../models/LogResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuditLogService {
    /**
     * Query Logs
     * @param userId
     * @param method
     * @param action
     * @param page
     * @param pageSize
     * @returns LogResponse Successful Response
     * @throws ApiError
     */
    public static queryLogsAuditLogGet(
        userId?: (number | null),
        method?: ('POST' | 'PUT' | 'PATCH' | 'DELETE' | null),
        action?: (string | null),
        page: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<LogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit_log/',
            query: {
                'userId': userId,
                'method': method,
                'action': action,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
