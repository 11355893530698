// noinspection SpellCheckingInspection

import { CollectionType, LanguageISO } from "./types";
import { Translation, TranslationCreate } from "../client/api";

type Keyword = {
  [T in LanguageISO]: string;
};

type Keywords = {
  [T in string]: Keyword;
};

const generalKeywords = {
  welcome: {
    de: "Willkommen, bitte anmelden!",
    it: "Benvenuto, per favore accedere!",
    en: "Welcome, please login!"
  },
  themedark: {
    de: "Light Mode",
    it: "Light mode",
    en: "Light mode"
  },
  themelight: {
    de: "Dark Mode",
    it: "Dark mode",
    en: "Dark mode"
  },
  downloadUnlimited: {
    de: "Laden Sie die Clava Sports App herunter, um auf unbegrenzte Funktionen zuzugreifen",
    it: "Scarica l'app Clava Sports per accedere a funzionalità illimitate",
    en: "Download Clava Sports App to Access to Unlimited Features"
  },
  downloadApp: {
    de: "Clava-App jetzt herunterladen",
    it: "Scarica l'App Clava adesso",
    en: "Downlaod Clava App Now"
  },
  downloadAppToConfirm: {
    de: "Bitte lade die App um dich zu verifizieren!",
    it: "Si prega di caricare l'app per registrarsi!",
    en: "Please load the app to register!"
  },
  back: {
    de: "Zurück",
    it: "Indietro",
    en: "Back"
  },
  register: {
    de: "Registrieren",
    it: "Registrati",
    en: "Register"
  },
  login: {
    de: "Anmelden",
    it: "Accedi",
    en: "Login"
  },
  logout: {
    de: "Abmelden",
    it: "Esci",
    en: "Log out"
  },
  alreadyRegistered: {
    de: "Hast du bereits ein Profil?",
    it: "Hai già un profilo?",
    en: "Do you already have a profile?"
  },
  password: {
    de: "Passwort",
    it: "Password",
    en: "Password"
  },
  passwordRepeat: {
    de: "Passwort wiederholen",
    it: "Ripeti password",
    en: "Repeat password"
  },
  pwNotSame: {
    de: "Passwörter stimmen nicht überein",
    it: "Le passwords non corrispondono",
    en: "Passwords do not match"
  },
  mailInvalid: {
    de: "E-Mail Adresse ungültig",
    it: "Indirizzo e-mail non valido",
    en: "Email invalid"
  },
  loginSuccess: {
    de: "Erfolgreich angemeldet",
    it: "Effettuato il login con successo",
    en: "Logged in successfully"
  },
  credentialsWrong: {
    de: "E-Mail oder Passwort falsch",
    it: "E-mail o password errata",
    en: "Email or passwort wrong"
  },
  mailGiven: {
    de: "E-Mail Adresse bereits vergeben",
    it: "Indirizzo e-mail già utilizzato",
    en: "Email adress already taken"
  },
  usernameGiven: {
    de: "Benutzername bereits vergeben",
    it: "Nome utente già utilizzato",
    en: "Username already taken"
  },
  mailOrTel: {
    de: "E-Mail Adresse oder Telefonnummer",
    it: "Indirizzo e-mail o Numero di telefono",
    en: "Email address or telephone number"
  },
  telIdentifier: {
    de: "Telefonnummer",
    it: "Numero di telefono",
    en: "Telephone number"
  },
  mailAddress: {
    de: "E-Mail Adresse",
    it: "Indirizzo e-mail",
    en: "Email address"
  },
  continue: {
    de: "Weiter",
    it: "Continua",
    en: "Continue"
  },
  pwForgot: {
    de: "Passwort vergessen?",
    it: "Dimenticato password?",
    en: "Forgot password?"
  },
  loginViaSms: {
    de: "Anmeldung via SMS",
    it: "Accedi tramite SMS",
    en: "Login via SMS"
  },
  linkViaSmsAndMail: {
    de: "Befolge die Anweisungen in der E-Mail/SMS die du bekommen hast um dich anzumelden.",
    it: "Segui le istruzioni contenute nell'e-mail/SMS che hai ricevuto per accedere.",
    en: "Follow the instructions in the email/SMS you received to log in."
  },
  linkViaSmsFailed: {
    de: "Bei der anmeldung ist etwas schief gelaufen, bitte melden Sie sich mit password an!",
    it: "Qualcosa è andato storto durante la registrazione, effettua il login con la tua password!",
    en: "Something went wrong during registration, please log in with password!"
  },
  active: {
    de: "Aktiv",
    it: "Attivo",
    en: "Active"
  },
  inactive: {
    de: "Inaktiv",
    it: "Inattivo",
    en: "Inactive"
  },
  deleted: {
    de: "Gelöscht",
    it: "Cancellato",
    en: "Deleted"
  },
  dashboard: {
    de: "Dashboard",
    it: "Dashboard",
    en: "Dashboard"
  },
  match: {
    de: "Spiel",
    it: "Partita",
    en: "Match"
  },
  matches: {
    de: "Spiele",
    it: "Partite",
    en: "Matches"
  },
  user: {
    de: "User",
    it: "User",
    en: "User"
  },
  player: {
    de: "Spieler",
    it: "Giocatore",
    en: "Player"
  },
  team: {
    de: "Mannschaft",
    it: "Squadra",
    en: "Team"
  },
  teams: {
    de: "Mannschaften",
    it: "Squadre",
    en: "Teams"
  },
  league: {
    de: "Liga",
    it: "Campionato",
    en: "League"
  },
  reporting: {
    de: "Meldungen",
    it: "Messaggi",
    en: "Reports"
  },
  log: {
    de: "Log",
    it: "Log",
    en: "Log"
  },
  liveTables: {
    de: "Live Tabellen",
    it: "Tabelle live",
    en: "Live Table"
  },
  advertising: {
    de: "Advertising",
    it: "Advertising",
    en: "Advertising"
  },
  news: {
    de: "News",
    it: "News",
    en: "News"
  },
  feed: {
    de: "Feed",
    it: "Feed",
    en: "Feed"
  },
  menu: {
    de: "Menu",
    it: "Menu",
    en: "Menu"
  },
  sports: {
    de: "Sportart",
    it: "Sport",
    en: "Sport"
  },
  chooseSports: {
    de: "Sportart wählen",
    it: "Seleziona sport",
    en: "Select Sport"
  },
  province: {
    de: "Provinz",
    it: "Provincia",
    en: "Province"
  },
  chooseProvince: {
    de: "Provinz wählen",
    it: "Seleziona provincia",
    en: "Select region"
  },
  chooseLeague: {
    de: "Liga wählen",
    it: "Seleziona campionato",
    en: "Select league"
  },
  SOCCER: {
    de: "Fußball",
    it: "Calcio",
    en: "Soccer"
  },
  HOCKEY: {
    de: "Hockey",
    it: "Hockey",
    en: "Hockey"
  },
  VOLLEYBALL: {
    de: "Volleyball",
    it: "Pallavolo",
    en: "Volleyball"
  },
  search: {
    de: "Suchen",
    it: "Ricerca",
    en: "Search"
  },
  searchNameId: {
    de: "Suche nach Name, ID",
    it: "Ricerca nome o ID",
    en: "Search for name or ID"
  },
  leagueManagement: {
    de: "Liga-Management",
    it: "Gestione del campionato",
    en: "League Management"
  },
  teamManagement: {
    de: "Team-Management",
    it: "Gestione delle squadre",
    en: "Team Management"
  },
  matchManagement: {
    de: "Match-Management",
    it: "Gestione delle partite",
    en: "Match Management"
  },
  matchLive: {
    de: "Match-Live",
    it: "Partite Live",
    en: "Match Live"
  },
  id: {
    de: "ID",
    it: "ID",
    en: "ID"
  },
  name: {
    de: "Name",
    it: "Nome",
    en: "Name"
  },
  type: {
    de: "Typ",
    it: "Tipo",
    en: "Type"
  },
  preset: {
    de: "Vorgabe",
    it: "Preimposta",
    en: "Preset"
  },
  status: {
    de: "Status",
    it: "Stato",
    en: "Status"
  },
  action: {
    de: "Aktion",
    it: "Azione",
    en: "Action"
  },
  "south-tyrol": {
    de: "BZ",
    it: "BZ",
    en: "BZ"
  },
  trentino: {
    de: "TR",
    it: "TR",
    en: "TR"
  },
  createLeague: {
    de: "Neue Liga erstellen",
    it: "Crea nuovo campionato",
    en: "Create new League"
  },
  reorderLeague: {
    de: "Ligen anordnen",
    it: "Riordina campionati",
    en: "Reorder leagues"
  },
  categories: {
    de: "Kategorien",
    it: "Categorie",
    en: "Categories"
  },
  save: {
    de: "Speichern",
    it: "Salva",
    en: "Save"
  },
  add: {
    de: "Hinzufügen",
    it: "Aggiungi",
    en: "Add"
  },
  transfer: {
    de: "Tranferieren",
    it: "Transfer",
    en: "Transfer"
  },
  bothTeams: {
    de: "Spielt beide Teams",
    it: "Gioca entrambe squadre",
    en: "Plays both teams"
  },
  server: {
    de: "Server",
    it: "Server",
    en: "Server"
  },
  sureLeave: {
    de: "Sicher, dass du die Seite verlassen möchtest? Es gibt nicht gespeicherte Änderungen!",
    it: "Sicuro di voler lasciare la pagina? Ci sono modifiche non salvate!",
    en: "Are you sure you want to leave? You have unsaved changes!"
  },
  proceed: {
    de: "Fortfahren",
    it: "Procedere",
    en: "Proceed"
  },
  cancel: {
    de: "Abbrechen",
    it: "Anulla",
    en: "Cancel"
  },
  cancelMatch: {
    de: "Absagen",
    it: "Cancella",
    en: "Cancel"
  },
  stayHere: {
    de: "Hier bleiben",
    it: "Rimani qui",
    en: "Stay here"
  },
  caution: {
    de: "Achtung",
    it: "Attenzione",
    en: "Caution"
  },
  fetchFailed: {
    de: "Daten vom Server konnten nicht abgerufen werden",
    it: "Impossibile recuperare i dati dal server",
    en: "Data could not be retrieved from the server"
  },
  saveSuccess: {
    de: "Erfolgreich gespeichert",
    it: "Salvato con successo",
    en: "Successfully saved"
  },
  saveSuccessCanCreateNew: {
    de: "Erfolgreich gespeichert, wenn sie nochmal speichern, wird noch eine Liga erstellt",
    it: "Salvato con successo, se salvi nuovamente, verrà creata un'altro campionato",
    en: "Successfully saved, if you save again, another league will be created"
  },
  saveSuccessCanCreateNewMatch: {
    de: "Erfolgreich gespeichert, wenn sie nochmal speichern, wird noch ein Match erstellt",
    it: "Salvato con successo, se salvi nuovamente, verrà creata un'altra partita",
    en: "Successfully saved, if you save again, another match will be created"
  },
  saveFailed: {
    de: "Konnte nicht gespeichert werden",
    it: "Non è stato possibile salvare",
    en: "Could not be saved"
  },
  close: {
    de: "Schließen",
    it: "Chiudi",
    en: "Close"
  },
  select: {
    de: "Wählen",
    it: "Seleziona",
    en: "Select"
  },
  options: {
    de: "Optionen",
    it: "Optioni",
    en: "Options"
  },
  changeImage: {
    de: "Bild ändern",
    it: "Cambia immagine",
    en: "Change image"
  },
  delete: {
    de: "Löschen",
    it: "Elimina",
    en: "Delete"
  },
  generalData: {
    de: "Generelle Daten",
    it: "Dati generali",
    en: "General Data"
  },
  nameDe: {
    de: "Name in Deutsch",
    it: "Nome in tedesco",
    en: "Name in german"
  },
  nameIt: {
    de: "Name in Italienisch",
    it: "Nome in intaliano",
    en: "Name in italian"
  },
  nameEn: {
    de: "Name in English",
    it: "Nome in inglese",
    en: "Name in english"
  },
  rankingCalculation: {
    de: "Tabellenkalkulation",
    it: "Calcolazione tabella",
    en: "Ranking calculation"
  },
  category: {
    de: "Kategorie",
    it: "Categoria",
    en: "Category"
  },
  serverGone: {
    de: "Der Server ist derzeit leider nicht erreichbar, bitte später nochmal versuchen!",
    it: "Purtroppo il server non è al momento disponibile, riprova più tardi!",
    en: "Unfortunately the server is currently unavailable, please try again later!"
  },
  noRights: {
    de: "Sie haben nicht ausreichende Rechte diese Seite zu benutzen. Verwenden Sie einen anderen account oder kontaktiere das Clava Team!",
    it: "Non disponi di diritti sufficienti per utilizzare questo sito. Utilizza un altro account o contatta il team Clava!",
    en: "You do not have sufficient rights to use this site. Use another account or contact the Clava team!"
  },
  comingSoon: {
    de: "Coming soon...",
    it: "Coming soon...",
    en: "Coming soon..."
  },
  comingSoonCont: {
    de: "Hier gibt's noch nichts zu sehen. Die Seite befindet sich im Aufbau!",
    it: "Non c'è ancora nulla da vedere qui. La pagina è in fase di costruzione!",
    en: "There's nothing to see here yet. This page is under construction!"
  },
  priority: {
    de: "Priorität",
    it: "Prioritá",
    en: "Priority"
  },
  adPosition: {
    de: "Position",
    it: "Posizione",
    en: "Position"
  },
  HOME_MATCH: {
    de: "Startseite",
    it: "Home",
    en: "Home"
  },
  MATCH_HISTORY_BOTTOM: {
    de: "Spielverlauf",
    it: "Cronologia delle partite",
    en: "Match History"
  },
  STANDINGS: {
    de: "Tabelle",
    it: "Classifica",
    en: "Standings"
  },
  TEAM_OF_THE_WEEK: {
    de: "Team der Woche",
    it: "Squadra della settimana",
    en: "Team of the Week"
  },
  USER_FEED: {
    de: "Feed",
    it: "Feed dell'utente",
    en: "User Feed"
  },
  MATCH_FEED: {
    de: "Spiel-Feed",
    it: "Feed delle partite",
    en: "Match Feed"
  },
  LEAGUE_MATCH_MATCH: {
    de: "Liga-Spiele",
    it: "Partite di campionato",
    en: "League Matches"
  },
  startTime: {
    de: "Start Zeitpunkt",
    it: "Tempo di inizio",
    en: "Start date"
  },
  startDate: {
    de: "Startdatum",
    it: "Data di inizio",
    en: "Start Date"
  },
  stopDate: {
    de: "Enddatum",
    it: "Data di fine",
    en: "End Date"
  },
  startStop: {
    de: "Start-Enddatum",
    it: "Data inizio-fine",
    en: "Start-End Date"
  },
  paused: {
    de: "Pausiert",
    it: "In pausa",
    en: "Paused"
  },
  yes: {
    de: "Ja",
    it: "Si",
    en: "Yes"
  },
  no: {
    de: "Nein",
    it: "No",
    en: "No"
  },
  sureDelete: {
    de: "Sind Sie sicher dass Sie '[name]' löschen möchten?",
    it: "Sei sicuro di voler eliminare '[name]'?",
    en: "Are you sure you want to delete '[name]'?"
  },
  deleteSuccess: {
    de: "Erfolgreich gelöscht",
    it: "Eliminato con successo",
    en: "Successfully deleted"
  },
  deleteFailed: {
    de: "Löschen fehlgeschlagen",
    it: "Eliminazione non riuscita",
    en: "Deletion failed"
  },
  edit: {
    de: "Bearbeiten",
    it: "Modifica",
    en: "Edit"
  },
  url: {
    de: "Link [https://www.example.com]",
    it: "Link [https://www.example.com]",
    en: "Link [https://www.example.com]"
  },
  backgroundColor: {
    de: "Hintergrundfarbe ['#abcdef' | 'transparent']",
    it: "Colore sfondo ['#abcdef' | 'transparent']",
    en: "Background color ['#abcdef' | 'transparent']"
  },
  selectDocument: {
    de: "Dokument auswählen",
    it: "Seleziona documento",
    en: "Select document"
  },
  selectImage: {
    de: "Bild auswählen",
    it: "Seleziona immagine",
    en: "Select image"
  },
  selectGif: {
    de: "Animation auswählen",
    it: "Seleziona animazione",
    en: "Select animation"
  },
  selectVideo: {
    de: "Video auswählen",
    it: "Seleziona video",
    en: "Select video"
  },
  selectMedia: {
    de: "Medien auswählen",
    it: "Seleziona media",
    en: "Select media"
  },
  upload: {
    de: "Upload",
    it: "Upload",
    en: "Upload"
  },
  importViaUrl: {
    de: "Import via URL",
    it: "Import via URL",
    en: "Import via URL"
  },
  mediathek: {
    de: "Mediathek",
    it: "Mediathek",
    en: "Mediathek"
  },
  uploadOrDragNDrop: {
    de: "Wähle eine Datei aus oder ziehe sie in dieses Feld",
    it: "Seleziona o trascina un file in questo campo",
    en: "Select a file or drag it into this field"
  },
  dropImageHere: {
    de: "Datei hier ablegen",
    it: "Rilascia il file qui",
    en: "Drop File here"
  },
  wrongFileType: {
    de: "Unzulässiger Dateityp! Erlaubte Formate:",
    it: "Tipo di file non valido! Tipi valido:",
    en: "Invalid file type! Valid types:"
  },
  imageTooBig: {
    de: "Die Datei ist zu groß, max. 200MB",
    it: "Il file è troppo grande, massimo 200 MB",
    en: "The file is too large, max. 200MB"
  },
  uploadFailed: {
    de: "Upload fehlgeschlagen",
    it: "Upload non riuscito",
    en: "Upload failed"
  },
  notFound: {
    de: "404 nicht gefunden",
    it: "404 non trovato",
    en: "404 not Found"
  },
  sectionAmount: {
    de: "Anzahl Halbzeiten",
    it: "Numero intervalli",
    en: "Section amount"
  },
  sectionPause: {
    de: "Dauer Halbzeitpause in Minuten",
    it: "Durata pausa intervallo in minuti",
    en: "Halftime break in minutes"
  },
  sectionDuration: {
    de: "Dauer pro Halbzeit in Minuten",
    it: "Durata per tempo in minuti",
    en: "Duration in minutes"
  },
  year: {
    de: "Jahr",
    it: "Anno",
    en: "Year"
  },
  matchDay: {
    de: "Spieltag",
    it: "Giornata",
    en: "Matchday"
  },
  matchDays: {
    de: "Anzahl Spieltage",
    it: "Numero di giornate",
    en: "Number of matchdays"
  },
  currentMatchDay: {
    de: "Aktueller Spieltag",
    it: "Giornata attuale",
    en: "Current matchday"
  },
  matchDaysHalfSeason: {
    de: "Anzahl Spieltage Halbsaison",
    it: "Numero di giornate metà stagione",
    en: "Number of matchdays half-season"
  },
  officialName: {
    de: "Offizieller Name",
    it: "Nome ufficiale",
    en: "Official name"
  },
  mainLeague: {
    de: "Ist Hauptliga",
    it: "È la lega principale",
    en: "Is main league"
  },
  hasPlayerStats: {
    de: "Hat Spieler-Statistiken",
    it: "Ha statistiche dei giocatori",
    en: "Has player statistics"
  },
  hasTeamStats: {
    de: "Hat Team-Statistiken",
    it: "Ha statistiche della squadra",
    en: "Has team statistics"
  },
  hasMOTM: {
    de: "Hat Man of The Match",
    it: "Ha Man of The Match",
    en: "Has Man of The Match"
  },
  hasFeed: {
    de: "Hat Feed",
    it: "Ha feed",
    en: "Has feed"
  },
  hasBetting: {
    de: "Hat Glücksspiel",
    it: "Ha scommesse",
    en: "Has betting"
  },
  hasLineup: {
    de: "Hat Aufstellung",
    it: "Ha la formazione",
    en: "Has lineup"
  },
  hasSquad: {
    de: "Hat Kader",
    it: "Ha squadra",
    en: "Has squad"
  },
  hasScorers: {
    de: "Hat Torschützen",
    it: "Ha i marcatori",
    en: "Has scorers"
  },
  externalId: {
    de: "Externe ID",
    it: "ID esterno",
    en: "External ID"
  },
  internalName: {
    de: "Interner Name",
    it: "Nome interno",
    en: "Internal Name"
  },
  change: {
    de: "Tauschen",
    it: "Cambia",
    en: "Change"
  },
  outOfComp: {
    de: "Außer Konkurrenz",
    it: "Fuori competizione",
    en: "Out of competition"
  },
  sureSetOutOfComp: {
    de: "Möchtest du die Teams außer Konkurrenz setzen, bzw. außer Konkurrenz status entfernen?",
    it: "Vuoi mettere le squadre fuori competizione o rimuovere il loro status di fuori competizione?",
    en: "Do you want to put the teams out of competition or remove out of competition status?"
  },
  addTeam: {
    de: "Team hinzufügen",
    it: "Aggiungi Squadra",
    en: "Add Team"
  },
  multipleMainLeagues: {
    de: "MEHR ALS 1 MAIN LIGA",
    it: "PIÙ DI 1 LEGA PRINCIPALE",
    en: "MORE THAN 1 MAIN LEAGUE"
  },
  selectLeague: {
    de: "Liga auswählen",
    it: "Seleziona campionato",
    en: "Select league"
  },
  selectLeagues: {
    de: "Ligen auswählen",
    it: "Seleziona campionati",
    en: "Select leagues"
  },
  chooseTeams: {
    de: "Teams auswählen",
    it: "Seleziona squadre",
    en: "Choose teams"
  },
  createAd: {
    de: "Werbebanner erstellen",
    it: "Creare banner pubblicitari",
    en: "Create advertising banners"
  },
  newEntry: {
    de: "Neuer Eintrag",
    it: "Nuova entrata",
    en: "New Entry"
  },
  noFilesInMediathek: {
    de: "Keine Dateien in der Mediathek vorhanden!",
    it: "Nessun file nel catalogo multimediale!",
    en: "No files available in the media library!"
  },
  copyName: {
    de: "Übersetzung kopieren",
    it: "Copia translatione",
    en: "Copy translation"
  },
  filters: {
    de: "Filtern",
    it: "Filtri",
    en: "Filters"
  },
  apply: {
    de: "Anwenden",
    it: "Applicare",
    en: "Apply"
  },
  deleteFailedBcsSquadMatch: {
    de: "Das Team kann nicht gelöscht werden da es bereits mit einen Kader oder ein Spiel verknüpft wurde.",
    it: "La squadra non può essere eliminata perché è già stata collegata a una rosa o a una partita.",
    en: "The team cannot be deleted because it has already been linked to a squad or a match."
  },
  deleteFailedBcsMatch: {
    de: "Wurde nicht gelöscht da es bereits mit einen Spiel verknüpft wurde.",
    it: "Non può essere eliminata perché è già stata collegata a una partita.",
    en: "Cannot be deleted because it has already been linked to a match."
  },
  deleteFailedMatch: {
    de: "Wurde nicht gelöscht, da Feed posts zu diesem Spiel existieren.",
    it: "Non è stato eliminato perché esistono post nel feed per questa partita.",
    en: "Was not deleted because feed posts about this match exist."
  },
  leagues: {
    de: "Ligen",
    it: "Campionati",
    en: "Leagues"
  },
  howPatchTeamEmblem: {
    de: "Wie würdest du gerne das Wappen ändern?",
    it: "Come vorresti cambiare lo stemma?",
    en: "How would you like to change the emblem?"
  },
  howCreateTeamEmblem: {
    de: "Wie würdest du gerne das Wappen erstllen?",
    it: "Come vorresti creare lo stemma?",
    en: "How would you like to create the emblem?"
  },
  adoptFormOtherTeam: {
    de: "Von anderen Team adoptieren",
    it: "Adotta da un'altra squadra",
    en: "Adopt from other team"
  },
  image: {
    de: "Bild",
    it: "Immagine",
    en: "Image"
  },
  givenName: {
    de: "Vorname",
    it: "Nome",
    en: "Given Name"
  },
  familyName: {
    de: "Nachname",
    it: "Cognome",
    en: "Family Name"
  },
  position: {
    de: "Position",
    it: "Ruolo",
    en: "Position"
  },
  connected: {
    de: "Verknüpft",
    it: "Connesso",
    en: "Connected"
  },
  notConnected: {
    de: "Nicht verknüpft",
    it: "Non connesso",
    en: "Not connected"
  },
  squad: {
    de: "Kader",
    it: "Rosa",
    en: "Squad"
  },
  addPlayer: {
    de: "Spieler hinzufügen",
    it: "Aggiungi giocatore",
    en: "Add player"
  },
  editPlayer: {
    de: "Spieler bearbeiten",
    it: "Modifica giocatore",
    en: "Edit Player"
  },
  clubManagement: {
    de: "Club Management",
    it: "Club Management",
    en: "Club Management"
  },
  createTeam: {
    de: "Team erstellen",
    it: "Crea squadra",
    en: "Create team"
  },
  addEmblem: {
    de: "Wappen hinzufügen",
    it: "Aggiungi stemma",
    en: "Add Emblem"
  },
  addLeague: {
    de: "Liga hinzufügen",
    it: "Aggiungi campionato",
    en: "Add league"
  },
  noneSelect: {
    de: "-- Keine --",
    it: "-- Nessuna --",
    en: "-- None --"
  },
  none: {
    de: "Keine",
    it: "Nessun",
    en: "None"
  },
  importCsv: {
    de: "Import csv",
    it: "Import csv",
    en: "Import csv"
  },
  row: {
    de: "Zeile",
    it: "Riga",
    en: "Row"
  },
  parse: {
    de: "Auslesen",
    it: "Analizza",
    en: "Parse"
  },
  hasHeaders: {
    de: "Hat Überschriften",
    it: "Ha intestazioni",
    en: "Has headers"
  },
  date: {
    de: "Datum",
    it: "Data",
    en: "Date"
  },
  time: {
    de: "Uhrzeit",
    it: "Tempo",
    en: "Time"
  },
  team1: {
    de: "Heimmannschaft",
    it: "Squadra di casa",
    en: "Home team"
  },
  team1Short: {
    de: "Heim",
    it: "Casa",
    en: "Home"
  },
  team2: {
    de: "Auswärtsmannschaft",
    it: "Squadra ospite",
    en: "Away team"
  },
  team2Short: {
    de: "Auswärts",
    it: "Ospite",
    en: "Away"
  },
  goal1: {
    de: "Tore Heim",
    it: "Gols casa",
    en: "Goals home"
  },
  goal2: {
    de: "Tore Auswärts",
    it: "Gols ospiti",
    en: "Gols away"
  },
  lineup1: {
    de: "Aufstellung Heim",
    it: "Rosa casa",
    en: "Lineup Home"
  },
  lineup2: {
    de: "Aufstellung Gast",
    it: "Rosa ospiti",
    en: "Lineup away"
  },
  emblemUrl: {
    de: "Logo URL",
    it: "Logo URL",
    en: "Logo URL"
  },
  createMatch: {
    de: "Spiel erstellen",
    it: "Crea partita",
    en: "Create match"
  },
  invalidRowLength: {
    de: "Falsche anzahl an Spalten in Zeile [row]",
    it: "Numero errato di colonne nella riga [row]",
    en: "Wrong number of columns in row [row]"
  },
  matchDayNoNum: {
    de: "Spieltag ist nicht numerisch in Zeile [row]",
    it: "La giornata non è numerica nella riga [row]",
    en: "Matchday is not numeric in row [row]"
  },
  dateFormatWrong: {
    de: "Datum ist nicht im korrekten Format in Zeile [row]",
    it: "La data non è nel formato corretto nella riga [row]",
    en: "Date is not in correct format in row [row]"
  },
  leagueNoNum: {
    de: "Liga ist nicht numerisch in Zeile [row]",
    it: "Campionato non è numerica nella riga [row]",
    en: "League is not numeric in row [row]"
  },
  teamIdNoNum: {
    de: "Team Id ist nicht numerisch in Zeile [row]",
    it: "Team Id non è numerica nella riga [row]",
    en: "Team Id is not numeric in row [row]"
  },
  playerPosInvalid: {
    de: "Posizion ist nicht gültig in Zeile [row]",
    it: "Posizione non è valido nella riga [row]",
    en: "Position is not valid in row [row]"
  },
  leagueNameTooShort: {
    de: "Name zu kurz",
    it: "Nome troppo corto",
    en: "Name too short"
  },
  noAois: {
    de: "Keine Provinzen",
    it: "Nessuna provincia",
    en: "No Province"
  },
  ignoreErrors: {
    de: "Fehler ignorieren",
    it: "Ignora gli errori",
    en: "Ignore errors"
  },
  leagueId: {
    de: "Liga ID",
    it: "ID campionato",
    en: "League ID"
  },
  startTask: {
    de: "Import starten",
    it: "Avvia l'importazione",
    en: "Start import"
  },
  check: {
    de: "Überprüfen",
    it: "Verifica",
    en: "Check"
  },
  importResult: {
    de: "Ergebnis des Imports",
    it: "Risultato dell'importazione",
    en: "Result of the import"
  },
  errors: {
    de: "Fehler",
    it: "Errori",
    en: "Errors"
  },
  events: {
    de: "Events",
    it: "Events",
    en: "Events"
  },
  addEvent: {
    de: "Event hinzufügen",
    it: "Aggiungi event",
    en: "Add event"
  },
  noPlayer: {
    de: "Keiner",
    it: "Nessuno",
    en: "None"
  },
  chance: {
    de: "Chance",
    it: "Chance",
    en: "Chance"
  },
  assist: {
    de: "Assist",
    it: "Assist",
    en: "Assist"
  },
  goalType: {
    de: "Tor Typ",
    it: "Tipo gol",
    en: "Goal type"
  },
  cardType: {
    de: "Karte",
    it: "Cartellino",
    en: "Card"
  },
  CARD: {
    de: "Karte",
    it: "Cartellino",
    en: "Card"
  },
  GOAL: {
    de: "Tor",
    it: "Gol",
    en: "Goal"
  },
  CHANCE: {
    de: "Chance",
    it: "Chance",
    en: "Chance"
  },
  CHANGE: {
    de: "Wechsel",
    it: "Cambio",
    en: "Change"
  },
  selectLocation: {
    de: "Standort auswählen",
    it: "Seleziona la località",
    en: "Select location"
  },
  createEvent: {
    de: "Event erstellen",
    it: "Crea evento",
    en: "Create event"
  },
  chooseScorer: {
    de: "Torschütze",
    it: "Marcatore",
    en: "Scorer"
  },
  chooseAssist: {
    de: "Assist wählen",
    it: "Seleziona assist",
    en: "Choose assist"
  },
  choosePlayerOut: {
    de: "Wähle Spieler AUS",
    it: "Selecziona sostit. OUT",
    en: "Select player OUT"
  },
  choosePlayerIn: {
    de: "Wähle Spieler EIN",
    it: "Selecziona sostit. IN",
    en: "Select player IN"
  },
  chooseWrongdoer: {
    de: "Übeltäter wählen",
    it: "Scegli giocatore",
    en: "Choose wrongdoer"
  },
  injured: {
    de: "Verletzung",
    it: "Lesione",
    en: "Injury"
  },
  minute: {
    de: "Minute",
    it: "Minuto",
    en: "Minute"
  },
  confirmed: {
    de: "C",
    it: "C",
    en: "C"
  },
  notConfirmed: {
    de: "N",
    it: "N",
    en: "N"
  },
  userManagement: {
    de: "Benutzerverwaltung",
    it: "Benutzerverwaltung",
    en: "Benutzerverwaltung"
  },
  username: {
    de: "Benutzername",
    it: "Nome utente",
    en: "Username"
  },
  email: {
    de: "E-Mail",
    it: "E-Mail",
    en: "E-Mail"
  },
  tel: {
    de: "Telefonnummer",
    it: "Telefono",
    en: "Phone"
  },
  resetCache: {
    de: "Cache zurücksetzen",
    it: "Reimposta la cache",
    en: "Reset cache"
  },
  resetCacheDesc: {
    de: "Ein Cache ist ein Servermodul, das häufig angeforderte Daten zwischenspeichert, um zukünftige Anfragen schneller zu bearbeiten. Diese Daten können das Ergebnis einer früheren Berechnung oder eine Kopie von an anderer Stelle gespeicherten Informationen sein. Manchmal kann der Cache jedoch Fehler verursachen, weshalb ein Zurücksetzen erforderlich sein kann. Ein Zurücksetzen des Caches führt allerdings zu erheblichen Leistungseinbußen. DRÜCKEN DIESE TASTE DAHER NICHT, wenn viele Benutzer aktiv sind!",
    it: "Una cache è un modulo sul server che memorizza i dati richiesti, consentendo di elaborare più rapidamente le richieste future per gli stessi dati. Questi dati possono essere il risultato di un calcolo precedente o una copia di informazioni memorizzate altrove. Tuttavia, a volte la cache può causare errori, rendendo necessario un reset. Il reset della cache comporta però una notevole perdita di prestazioni. QUINDI NON PREMERE QUESTO PULSANTE, se ci sono molti utenti attivi!",
    en: "A cache is a module on the server that stores requested data so that future requests for that data can be served faster; the data stored in a cache might be the result of an earlier computation or a copy of data stored elsewhere. Sometimes the cache can be the reason for errors, so it's necessary to reset it. But resetting it will cause huge performance loss, so DO NOT PRESS THIS BUTTON if there are a lot of active users at the moment!"
  },
  tournament: {
    de: "Turnier",
    it: "Torneo",
    en: "Tournament"
  },
  tournamentManagement: {
    de: "Turnier-Management",
    it: "Gestione del torneo",
    en: "Tournament Management"
  },
  cup: {
    de: "Pokal",
    it: "Coppa",
    en: "Cup"
  },
  group: {
    de: "Gruppe",
    it: "Gruppa",
    en: "Group"
  },
  groups: {
    de: "Gruppen",
    it: "Gruppi",
    en: "Groupd"
  },
  stages: {
    de: "Knockout",
    it: "Knockout",
    en: "Knockout"
  },
  noGroup: {
    de: "Keine Gruppe",
    it: "nessun gruppa",
    en: "No group"
  },
  isLooserBracket: {
    de: "Verliererrunde",
    it: "Girone perdenti",
    en: "Looser bracket"
  },
  addMatch: {
    de: "Spiel hinzufügen",
    it: "Aggiungi partita",
    en: "Add match"
  },
  uploadTeamEmblem: {
    de: "Vom Server zuschneiden lassen",
    it: "Chiedi al server di ritagliarlo",
    en: "Crop from server"
  },
  createDate: {
    de: "Erstelldatum",
    it: "Data di creazione",
    en: "Create date"
  },
  title: {
    de: "Titel",
    it: "Titolo",
    en: "Titel"
  },
  summary: {
    de: "Zusammenfassung",
    it: "Riepilogo",
    en: "Summary"
  },
  body: {
    de: "Haupttext",
    it: "Corpo",
    en: "Body"
  },
  createBlog: {
    de: "Artikel erstellen",
    it: "Crea articolo",
    en: "Create article"
  },
  all: {
    de: "Alle",
    it: "Tutti",
    en: "All"
  },
  allSelect: {
    de: " --- Alle ---",
    it: " --- Tutti ---",
    en: " --- All ---"
  },
  realtimeOverview: {
    de: "Echtzeitübersicht",
    it: "Panoramica in tempo reale",
    en: "Realtime overview"
  },
  users: {
    de: "Benutzer",
    it: "Utenti",
    en: "Users"
  },
  online: {
    de: "Aktiv",
    it: "Attivo",
    en: "Active"
  },
  language: {
    de: "Sprache",
    it: "Lingua",
    en: "Language"
  },
  german: {
    de: "Deutsch",
    it: "Tedesco",
    en: "German"
  },
  italian: {
    de: "Italienisch",
    it: "Italiano",
    en: "Italian"
  },
  english: {
    de: "Englisch",
    it: "Inglese",
    en: "English"
  },

  score: {
    de: "Resultat",
    it: "Risultato",
    en: "Result"
  },
  today: {
    de: "Heute",
    it: "Oggi",
    en: "Today"
  },
  tomorrow: {
    de: "Morgen",
    it: "Domani",
    en: "Tomorrow"
  },
  yesterday: {
    de: "Gestern",
    it: "Ieri",
    en: "Yesterday"
  },
  bulkActions: {
    de: "Bulk Aktionen",
    it: "Azioni bulk",
    en: "Bulk actions"
  },
  editMode: {
    de: "Bearbeitungsmodus",
    it: "Modo modifica",
    en: "Edit mode"
  },
  changes: {
    de: "Änderungen",
    it: "Cambi",
    en: "Changes"
  },
  homeTable: {
    de: "Heim",
    it: "Casa",
    en: "Home"
  },
  allTable: {
    de: "Gesamt",
    it: "Totale",
    en: "Overall"
  },
  awayTable: {
    de: "Auswärts",
    it: "Trasferta",
    en: "Away"
  },
  difference: {
    de: "DIF",
    it: "DIF",
    en: "DIF"
  },
  matchesShort: {
    de: "SP",
    it: "G",
    en: "M"
  },
  points: {
    de: "PT",
    it: "PT",
    en: "PTS"
  },
  wins: {
    de: "W",
    it: "V",
    en: "W"
  },
  draws: {
    de: "D",
    it: "N",
    en: "D"
  },
  losses: {
    de: "L",
    it: "P",
    en: "L"
  },
  goalsOut: {
    de: "F",
    it: "F",
    en: "F"
  },
  goalsIn: {
    de: "A",
    it: "S",
    en: "A"
  },
  fatture: {
    de: "Rechnungen",
    it: "Fatture",
    en: "Invoices"
  },
  createPunishment: {
    de: "Bestrafung erstellen",
    it: "Crea punizione",
    en: "Create punishment"
  },
  causeDe: {
    de: "Grund DE",
    it: "Causa DE",
    en: "Cause DE"
  },
  causeIt: {
    de: "Grund IT",
    it: "Causa IT",
    en: "Cause IT"
  },
  causeEn: {
    de: "Grund EN",
    it: "Causa EN",
    en: "Cause EN"
  },
  deduction: {
    de: "Punkteabzug",
    it: "Punti detratti",
    en: "Points deduction"
  },
  content: {
    de: "Textinhalt",
    it: "Contenuto",
    en: "Content"
  },
  media: {
    de: "Medien",
    it: "Media",
    en: "Media"
  },
  author: {
    de: "Autor",
    it: "Autore",
    en: "Author"
  },
  likes: {
    de: "Likes",
    it: "Mi piace",
    en: "Likes"
  },
  comments: {
    de: "Kommentare",
    it: "Commenti",
    en: "Comments"
  },
  convert: {
    de: "Konvertieren",
    it: "Converta",
    en: "Convert"
  },
  downloadMedia: {
    de: "Medien herunterladen",
    it: "Scarica media",
    en: "Download media"
  },
  download: {
    de: "Download",
    it: "Download",
    en: "Download"
  },
  canConvertVideo: {
    de: "Videos müssen zuerst im originalformat heruntergeladen werden, es kann hier dann wieder hochgeladen und auf das In-App Format zugeschnitten werden!",
    it: "I video devono prima essere scaricati nel formato originale, poi possono essere caricati nuovamente qui e adattati al formato in-app!",
    en: "Videos must first be downloaded in their original format, they can then be uploaded here again and trimmed to the in-app format!"
  },
  tryAgain: {
    de: "Nochmal versuchen",
    it: "Prova ancora",
    en: "Try again"
  },
  makeSmaller: {
    de: "Verkleinern",
    it: "Riduci dimensione",
    en: "Resize"
  },
  birthdate: {
    de: "Geburtstag",
    it: "Data di nascita",
    en: "Birthdate"
  },
  foot: {
    de: "Fuß",
    it: "Piede",
    en: "Foot"
  },
  height: {
    de: "Größe [cm]",
    it: "Altezza [cm]",
    en: "Height [cm]"
  },
  inAppLink: {
    de: "In-App verlinkung",
    it: "In-App link",
    en: "In-App link"
  },
  params: {
    de: "Parameter",
    it: "Parametri",
    en: "Parameters"
  },
  noParamsForScreen: {
    de: "Keine Parameter für diesen Screen verfügbar",
    it: "Nessun parametro disponibile per questa schermata",
    en: "No parameters available for this screen"
  },
  fileTooBigResize: {
    de: "Die Datei ist zu groß! Bitte verkleinern oder andere datei auswählen",
    it: "Il file è troppo grande! Si prega di ridurre le dimensioni o selezionare un altro file",
    en: "The file is too large! Please resize or select another file"
  },
  scale: {
    de: "Größe",
    it: "Scale",
    en: "Scale"
  },
  ffmpegInfo: {
    de: "ffmpeg ist ein effizientes Tool zum Bearbeiten von Bildern und Videos, leider kann man nicht automatisch feststellen, ob das Verkleinern erfolgreich war. Im Log stehen jedoch nützliche Informationen!",
    it: "ffmpeg è uno strumento efficiente per modificare immagini e video, purtroppo non è possibile determinare automaticamente se il ridimensionamento è stato eseguito correttamente, ma nel log ci sono informazioni utili!",
    en: "ffmpeg is an efficient tool for editing images and videos, unfortunately, it is not possible to automatically determine if the resizing was successful, but the log contains useful information!"
  },
  copyLog: {
    de: "Log kopieren",
    it: "Copy Log",
    en: "Copy Log"
  },
  retry: {
    de: "Nochmal versuchen",
    it: "prova ancora",
    en: "Try again"
  },
  filenameCropped: {
    de: "Ziel-Dateiname (für Konvertierung Endung ändern)",
    it: "Nome del file di destinazione (cambia estensione per la conversione)",
    en: "Target filename (change extension for conversion)"
  },
  adStatistics: {
    de: "Werbe Statistik",
    it: "Ad Statistics",
    en: "Ad Statistics"
  },
  activeOnly: {
    de: "Nur Aktive",
    it: "Solo attive",
    en: "Active only"
  },
  byArea: {
    de: "Provinz",
    it: "Provincia",
    en: "Province"
  },
  byAreaCont: {
    de: "Wie viel Werbebanner gibt es pro Provinz",
    it: "Quanti banner pubblicitari ci sono per provincia?",
    en: "How many advertising banners are there per province"
  },
  byPosition: {
    de: "Werbe Position",
    it: "Ad Placement",
    en: "Ad Placement"
  },
  byPositionCont: {
    de: "Wie viele Werbebanner gibt es pro Werbe-Position",
    it: "Quanti banner pubblicitari ci sono per posizione?",
    en: "AHow many advertising banners are there per position"
  },
  byPrio: {
    de: "Priorität",
    it: "Prioritá",
    en: "Priority"
  },
  byPrioCont: {
    de: "Aufteilung der Priorität pro Banner, Position und Provinz!",
    it: "Distribuzione della priorità per banner, posizione e provincia!",
    en: "Distribution of priority per banner, position and province!"
  },
  clicks: {
    de: "Clicks",
    it: "Clicks",
    en: "Clicks"
  },
  method: {
    de: "Methode",
    it: "Method",
    en: "Method"
  },
  entityId: {
    de: "Entitäts-ID",
    it: "Id di entità",
    en: "Entity ID"
  },
  entityType: {
    de: "Entitätstyp",
    it: "Tipo di entità",
    en: "Entity type"
  },
  statusCode: {
    de: "Status code",
    it: "Codice di stato",
    en: "Status code"
  },
  duration: {
    de: "Ausführungsdauer",
    it: "Tempo di esecuzione",
    en: "Execution time"
  },
  details: {
    de: "Details",
    it: "Dettagli",
    en: "Details"
  },
  foundPlayers: {
    de: "Gefundene Spieler",
    it: "Giocatori trovati",
    en: "Found players"
  },
  emptyString: {
    de: "",
    it: "",
    en: ""
  }
};

export declare type TranslatorKeys = keyof typeof generalKeywords;
export type Translatable = CollectionType & {
  name: Translation;
};
const keywords: Keywords = { ...generalKeywords };

export function translate(
  key: TranslatorKeys | Translation | TranslationCreate,
  lang: LanguageISO,
  params?: { [T in string]: string }
): string {
  if (typeof key === "object") return showTranslated(key, lang);
  const keyword: Keyword = keywords[key];

  if (typeof keyword === "undefined") {
    return key;
  }
  if (params === undefined) {
    return keyword[lang];
  }
  let text = keyword[lang];
  const paramKeys = Object.keys(params);
  paramKeys.forEach((param) => {
    text = text.replace(param, params[param]);
  });
  return text;
}

/**
 *
 * @param translation
 * @param lang
 */
export function showTranslated(
  translation: Translation | TranslationCreate,
  lang: LanguageISO
): string {
  switch (lang) {
    case "de":
      return translation.textDE;
    case "en":
      return translation.textEN;
    case "it":
      return translation.textIT;
    default:
      return "text" in translation ? translation.text : translation.textDE;
  }
}
