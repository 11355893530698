import React from "react";

const JsonSyntaxHighlighter: React.FC<{ obj: object, className?: string }> = ({
                                                                                className,
                                                                                obj
                                                                              }) => {
  if (Object.keys(obj).length === 0) {
    return null;
  }

  let json = JSON.stringify(obj, undefined, 2);
  json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (match) => {
      let cls = "text-darkorange";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "text-red";
        } else {
          cls = "text-green";
        }
      } else if (/true|false/.test(match)) {
        cls = "text-primary";
      } else if (/null/.test(match)) {
        cls = "text-magenta";
      }
      return `<span class='${cls}'>${match}</span>`;
    });

  return (
    <div className={className}>
      <pre dangerouslySetInnerHTML={{ __html: json }} />
    </div>
  );
};

export default JsonSyntaxHighlighter;
