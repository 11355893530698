import React, { useCallback, useContext, useMemo, useState } from "react";
import { translate } from "../../config/translator";
import ClavaButton from "../../components/Atoms/ClavaButton";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { ClavaSelectItems } from "../../config/types";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import AsideModal from "../../components/Layout/AsideModal";
import { ClavaContext } from "../../config/contexts";

const Screens = {
  HomeNavigator: {
    screen: ["Home", "LeagueList", "Feed", "News", "MyTeam"]
  },
  League: {
    leagueId: 0,
    screen: ["LeagueMatches", "LeagueTable", "LeagueStatisticsTeam", "LeagueStatisticsPlayer", "LeagueVideos"]
  },
  Match: {
    matchId: 0,
    screen: ["MatchInfo", "MatchHistory", "MatchLineup", "MatchTable", "MatchMotm"]
  },
  Team: {
    teamId: 0,
    screen: ["TeamInfo", "TeamMatches", "TeamTable", "TeamSquad", "TeamStatistics"]
  },
  ProfileScreen: undefined,
  Register: undefined,
  Login: undefined,
  GetPremium: undefined,
  BeInsider: undefined,
  BePlayer: undefined,
  Player: { playerId: 0 },
  Cups: {
    cupId: 0
  },
  EditTeam: { editTeam: 0 },
  EditMatch: { editMatch: 0 },
  EditSquad: { teamId: 0 },
  DoTransfer: { teamId: 0 },
  FeedCompose: undefined,
  Settings: undefined,
  Logs: undefined,
  Search: undefined
};
const ScreenBtn: React.FC<{
  s: string;
  screen: string | undefined,
  setScreen: React.Dispatch<React.SetStateAction<string | undefined>>,
  setParams: React.Dispatch<React.SetStateAction<Record<string, number | string> | undefined>>
}> = ({ screen, s, setScreen, setParams }) => {
  const onPress = useCallback(() => {
    setScreen(s);
    setParams(undefined);
  }, [setParams, setScreen, s]);
  return (
    <ClavaButton onClick={onPress} secondary={screen !== s} className={"m-1"}>
      {s}
    </ClavaButton>
  );
};
const ParamsBtn: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<Record<string, number | string> | undefined>>,
  params: Record<string, number | string> | undefined;
  availParams: Record<string, number | string[]>; p: string,
}> = ({ availParams, params, p, setParams }) => {
  const paramType = useMemo(() => {
    return availParams[p as keyof typeof availParams];
  }, [availParams, p]);
  const value = useMemo(() => {
    if (!params)
      return undefined;
    const val: string | number | undefined = params[p as keyof typeof params];
    if (typeof val === "number") {
      return val.toString(10);
    }
    return val;
  }, [p, params]);

  const onChangeStr = useCallback((val: string | undefined) => {
    if (val && val.length !== 0) {
      setParams(prms => ({
        ...prms,
        [p]: parseInt(val, 10)
      }));
    } else {
      setParams(prms => {
        // @ts-ignore
        delete prms[p];
        return prms;
      });
    }

  }, [setParams, p]);
  const onChangeScreen = useCallback((val: string | undefined) => {
    if (val) {
      setParams(prms => ({
        ...prms,
        [p]: val
      }));
    } else {
      setParams(prms => {
        // @ts-ignore
        delete prms[p];
        return prms;
      });
    }
  }, [setParams, p]);
  if (typeof paramType === "undefined")
    return null;
  if (typeof paramType === "number") {
    return (
      <ClavaTextInput onChange={onChangeStr} value={value ?? ""} label={p} />
    );
  }
  if (p === "screen") {
    const items = (paramType as string[]).map<ClavaSelectItems>((sc) => ({
      key: sc,
      label: sc
    }));
    return (
      <ClavaSelect uniqueId={"param-select-" + p} items={items} onChange={onChangeScreen} label={p}
                   value={value ?? ""} />
    );
  }
  return null;
};
const LinkGenerator: React.FC<{ onSave: (url: string) => void, onClose: () => void }> = ({
                                                                                           onClose,
                                                                                           onSave
                                                                                         }) => {
  const { l } = useContext(ClavaContext);
  const [screen, setScreen] = useState<string>();
  const [params, setParams] = useState<Record<string, number | string>>();
  const availParams = useMemo(() => {
    return Screens[screen as keyof typeof Screens];
  }, [screen]);
  const queryString = useMemo(() => {
    if (!params)
      return "";
    return "?" + Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key as keyof typeof params])}`)
      .join("&");
  }, [params]);
  const link = useMemo(() => {
    return `inapplink://${screen}${queryString}`;
  }, [queryString, screen]);
  const generateLink = useCallback(() => {
    onSave(link);
    onClose();
  }, [onSave, onClose, link]);
  return (
    <AsideModal close={onClose} title={translate("inAppLink", l)}
                saveClb={generateLink}>
      <div className="flex flex-col items-stretch max-w-xl">
        <div className="border border-primary p-2 text-center">
          <span>{link}</span>
        </div>
        <h3>Screen</h3>
        <div className="flex flex-row flex-wrap">
          {Object.keys(Screens).map((s) => (
            <ScreenBtn s={s} screen={screen} setScreen={setScreen} setParams={setParams}
                       key={"screen-" + s} />
          ))}
        </div>
        <h3>{translate("params", l)}</h3>
        {!!availParams ? Object.keys(availParams).map((p) => (
          <ParamsBtn setParams={setParams} params={params} availParams={availParams} p={p}
                     key={"param-" + p} />
        )) : (
          <span>{translate("noParamsForScreen", l)}</span>
        )}
      </div>
    </AsideModal>
  );
};

export default LinkGenerator;
