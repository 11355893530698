import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { Image as UserImage } from "../../client/api";
import ClavaImage from "../Atoms/ClavaImage";
import ClavaButton from "../Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translate } from "../../config/translator";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { faClipboard, faDownload } from "@fortawesome/pro-light-svg-icons";
import LoadingBlock from "../Atoms/LoadingBlock";
import ClavaTextInput from "../Atoms/ClavaTextInput";

const FileUploaderResizer: React.FC<{
  file: File,

  finish: () => void,
}> = ({
        file, finish
      }) => {
  const { l } = useContext(ClavaContext);
  const [size, setSize] = useState<{ w: number; h: number }>();
  const src = useMemo(() => URL.createObjectURL(file), [file]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [downloadLink, setDownloadLink] = useState<string>();
  const [scale, setScale] = useState("0.5");
  const [outName, setOutName] = useState("crop_" + file.name);
  const [filterComplex, setFilterComplex] = useState("");
  const [messages, setMessages] = useState<string[]>([]);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setSize({ w: img.width, h: img.height });
      const w = img.width / 2;
      setFilterComplex(`[0:v] scale=${w}:-1:flags=lanczos,split [a][b]; [a] palettegen=reserve_transparent=on:transparency_color=ffffff [p]; [b][p] paletteuse`);
    };
    img.src = src;
  }, [src]);
  const previewFile = useMemo<UserImage | undefined>(() => {
    if (size) {
      return {
        url: src,
        uploadDate: "",
        id: "asf",
        aspectRatio: size.w / size.h,
        cropAspectRatio: size.w / size.h,
        cropY: 0,
        cropX: 0,
        cropScale: 1
      };
    }
    return undefined;
  }, [size, src]);

  const setScaleCont = useCallback((val: string) => {
    setScale(val);
    const floatScale = parseFloat(val);
    if (size && !Number.isNaN(floatScale)) {
      setFilterComplex(`[0:v] scale=${size.w * floatScale}:-1:flags=lanczos,split [a][b]; [a] palettegen=reserve_transparent=on:transparency_color=ffffff [p]; [b][p] paletteuse`);
    }
  }, [size]);
  const imageStyle = useMemo(() => {
    if (!size) {
      return {
        width: "25%"
      };
    }
    return {
      width: `${size.w / size.h * 25}%`
    };
  }, [size]);
  const onSmallerStartUpload = useCallback(() => {
    const ffmpeg = new FFmpeg();
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    setLoading(true);
    const errorhandler = () => {
      setLoading(false);
      setError(translate("uploadFailed", l));
    };
    toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript").then((coreURL) => {
      toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm").then((wasmURL) => {
        ffmpeg.on("log", (message) => {
          setMessages(msg => msg.concat(message.message));
        });
        ffmpeg.load({
          coreURL,
          wasmURL
        }).then(() => {
          fetchFile(file).then((blob) => {
            ffmpeg.writeFile(file.name, blob).then((ok) => {
              if (ok) {
                setMessages(msg => msg.concat(`ffmpeg -i "${file.name}" ${filterComplex.length !== 0 ? `-filter_complex "${filterComplex}" ` : ""}"${outName}"`));
                const args = ["-i", file.name];
                if (filterComplex.length !== 0) {
                  args.push("-filter_complex");
                  args.push(filterComplex);
                }
                args.push(outName);
                ffmpeg.exec(args).then(() => {
                  ffmpeg.readFile(outName).then((data) => {
                    setLoading(false);
                    const extension = file.name.split(".")[1].toLowerCase();
                    setDownloadLink(URL.createObjectURL(new Blob([data], { type: `image/${extension}` })));
                  }, errorhandler);
                }, errorhandler);
              } else {
                errorhandler();
              }
            }, errorhandler);
          }, errorhandler);
        }, errorhandler);
      }, errorhandler);
    }, errorhandler);
  }, [outName, filterComplex, file, l]);
  const onCopy = useCallback(() => {
    const str = messages.join("\n");
    navigator.clipboard.writeText(str).then();
  }, [messages]);
  const onReset = useCallback(() => {
    setDownloadLink(undefined);
  }, []);
  if (!previewFile)
    return null;
  return (
    <div className="flex flex-col items-stretch justify-stretch">
      {!!error && (
        <div className="flex flex-col items-center justify-start">
          <span className="text-red font-semibold mb-4 block">{error}</span>
        </div>
      )}
      <div className="flex flex-row items-center justify-start">

        <div className="flex-1 relative mr-4 flex flex-row items-center justify-center">
          <div style={imageStyle} className="pattern-rectangles pattern-pattern-gray pattern-bg-white
  pattern-size-4 pattern-opacity-100">
            <ClavaImage width={"100%"} image={previewFile} />
          </div>
        </div>

        <LoadingBlock isLoading={loading} className="flex-1">
          <span>{translate("fileTooBigResize", l)}</span>
          <div className="flex flex-row items-center justify-evenly">
            <ClavaTextInput error={Number.isNaN(parseFloat(scale))} onChange={setScaleCont}
                            value={scale}
                            label={translate("scale", l)} className="flex-1 mx-1" />
            <div className="flex-1 mx-1">
              <span>Size: {(file.size / 1000).toFixed(1)}kB {"=>"} {!Number.isNaN(parseFloat(scale)) ? `~${((file.size * Math.sqrt(parseFloat(scale))) / 1000).toFixed(1)}kB` : ""}</span>
              <br />
              <span>X: {size && !Number.isNaN(parseFloat(scale)) ? `${size.w}px => ${(size.w * parseFloat(scale)).toFixed(0)}px` : ""}</span>
              <br />
              <span>Y: {size && !Number.isNaN(parseFloat(scale)) ? `${size.h}px => ${(size.h * parseFloat(scale)).toFixed(0)}px` : ""}</span>
            </div>
          </div>
          <ClavaTextInput onChange={setFilterComplex} value={filterComplex}
                          label={"-filter_complex"} />
          <ClavaTextInput onChange={setOutName} value={outName}
                          label={translate("filenameCropped", l)} />
          <div className="flex flex-row items-center justify-evenly mt-2">
            <ClavaButton onClick={finish} secondary>{translate("back", l)}</ClavaButton>
            {!!downloadLink ? (
              <>
                <a href={downloadLink}
                   className="rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 bg-primary text-center active:bg-btn-active hover:bg-btn-active text-font-dark"
                   download={"crop_" + file.name}>
                  <FontAwesomeIcon icon={faDownload} className={"w-4 h-4 mr-4"} />
                  {translate("download", l)}
                </a>
                <ClavaButton
                  onClick={onReset}>{translate("retry", l)}</ClavaButton>

              </>
            ) : (
              <ClavaButton disabled={Number.isNaN(parseFloat(scale))}
                           onClick={onSmallerStartUpload}>{translate("makeSmaller", l)}</ClavaButton>
            )}
          </div>
        </LoadingBlock>
      </div>
      {messages.length !== 0 && (
        <div>
          <h6 className="font-bold text-lg">Log</h6>
          <span>{translate("ffmpegInfo", l)}</span>
        </div>)}
      <div className="max-h-96 mt-4 overflow-scroll">
        {messages.map(msg => (
          <div key={msg}>
            <code className="text-nowrap">{msg}</code>
          </div>
        ))}
      </div>
      {messages.length !== 0 && (
        <ClavaButton onClick={onCopy}
        >
          <FontAwesomeIcon icon={faClipboard} className={"w-4 h-4 mr-4"} />
          {translate("copyLog", l)}
        </ClavaButton>
      )}
    </div>
  );
};

export default FileUploaderResizer;
