import React, { useCallback, useContext, useMemo } from "react";
import RoundedBlock from "../Atoms/RoundedBlock";
import { ClavaContext } from "../../config/contexts";
import LogoutIcon from "../Icons/LogoutIcon";
import client from "../../client";
import ClavaImage from "../Atoms/ClavaImage";
import UserIcon from "../Icons/UserIcon";
import { LanguageLocaleEnum, ScopeEnum, SportEnum } from "../../client/api";
import { showTranslated, translate } from "../../config/translator";
import SoccerIcon from "../Icons/SoccerIcon";
import HockeyIcon from "../Icons/HockeyIcon";
import VolleyIcon from "../Icons/VolleyIcon";
import ProvinceIcon from "../Icons/ProvinceIcon.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faServer } from "@fortawesome/pro-solid-svg-icons";
import {
  AS_ENDPOINT,
  AS_FILTER_AOI,
  BETA_ENDPOINT,
  DEV_ENDPOINT,
  PROD_ENDPOINT,
  serverItems,
  SPORTS,
  STAG_ENDPOINT
} from "../../config/constants";
import ClavaSelect, { mapTranslationToItem } from "../Atoms/ClavaSelect";
import { isAdmin, makeAoiItems } from "../../config/utils";
import { ClavaSelectItems } from "../../config/types";


const Header: React.FC = () => {

  const {
    user,
    l,
    aois,
    regions,
    filterAoi,
    setFilterAoi,
    sports,
    setSports, setLang,
    setUser
  } = useContext(ClavaContext);
  const onLogout = useCallback(() => {
    client().logout();
    setUser(undefined);
    location.reload();
  }, [setUser]);
  const onSetSportsCont = useCallback((e: string | undefined) => {
    if (e)
      setSports(e as SportEnum);
  }, [setSports]);
  const onSetProvinceCont = useCallback((e: string | undefined) => {
    if (e) {
      localStorage.setItem(AS_FILTER_AOI, e);
      setFilterAoi(e);
    }
  }, [setFilterAoi]);
  const onSetServer = useCallback((e: string | undefined) => {
    if (e) {
      window.localStorage.setItem(AS_ENDPOINT, e);
      setTimeout(() => {
        location.reload();
      }, 100);
    }
  }, []);
  const isDev = useMemo(() => client().getEndpoint() === DEV_ENDPOINT, []);
  const isBeta = useMemo(() => client().getEndpoint() === BETA_ENDPOINT, []);
  const isStag = useMemo(() => client().getEndpoint() === STAG_ENDPOINT, []);
  const sportItems = useMemo(() => {
    return SPORTS.map(mapTranslationToItem(l));
  }, [l]);
  const aoiItems = useMemo(() => {
    return makeAoiItems(regions, aois, l);
  }, [l, regions, aois]);
  const langItems = useMemo<ClavaSelectItems[]>(() => {
    return [
      {
        label: translate("german", l),
        key: "de"
      },
      {
        label: translate("italian", l),
        key: "it"
      },
      {
        label: translate("english", l),
        key: "en"
      }
    ];
  }, [l]);
  const onChangeLangCont = useCallback((val: string | undefined) => {
    if (val) {
      setLang(val as LanguageLocaleEnum);
    }
  }, [setLang]);
  return (
    <RoundedBlock className="mb-2">
      <div className="flex flex-row items-stretch justify-between">
        <div className="flex flex-row items-center justify-start">
          <div
            className="flex flex-row items-center justify-center bg-light-gray dark:bg-light-gray-dark rounded-full p-2 ml-4">
            {sports === SportEnum.HOCKEY ? (<HockeyIcon />) : sports === SportEnum.VOLLEYBALL ? (
              <VolleyIcon />) : (<SoccerIcon />)}
          </div>
          <div className="flex flex-col items-start justify-evenly mx-4">
            <span>{translate("chooseSports", l)}</span>
            <ClavaSelect uniqueId={"sports"} items={sportItems} onChange={onSetSportsCont}
                         value={sports} transparent />
          </div>
          <div
            className="border-r border-r-light-gray dark:border-r-light-gray-dark self-stretch ml-4"></div>
          <div
            className="flex flex-row items-center justify-center bg-light-gray dark:bg-light-gray-dark rounded-full p-2 ml-8">
            <ProvinceIcon />
          </div>
          <div className="flex flex-col items-start justify-evenly mx-4">
            <span>{translate("chooseProvince", l)}</span>
            <ClavaSelect uniqueId={"aois"} items={aoiItems} onChange={onSetProvinceCont} transparent
                         value={filterAoi} />
          </div>
          {isAdmin(user) && (<>
            <div
              className="border-r border-r-light-gray dark:border-r-light-gray-dark self-stretch ml-4"></div>
            <div
              className={`flex flex-row items-center justify-center rounded-full p-2 ml-8 ${
                isBeta ? "!bg-orange" : isStag ? "!bg-green" : isDev ? "!bg-red" : "bg-light-gray dark:bg-light-gray-dark"
              }`}>
              <FontAwesomeIcon icon={faServer} />
            </div>
            <div className="flex flex-col items-start justify-evenly mx-4">
              <span>{translate("server", l)}</span>
              <ClavaSelect uniqueId={"server"} items={serverItems} onChange={onSetServer}
                           transparent
                           value={isDev ? DEV_ENDPOINT : isStag ? STAG_ENDPOINT : isBeta ? BETA_ENDPOINT : PROD_ENDPOINT} />
            </div>
          </>)}
          <div
            className="border-r border-r-light-gray dark:border-r-light-gray-dark self-stretch ml-4"></div>
          <div
            className="flex flex-row items-center justify-center bg-light-gray dark:bg-light-gray-dark rounded-full p-2 ml-8">
            <FontAwesomeIcon icon={faLanguage} className="w-4 h-4" />
          </div>
          <div className="flex flex-col items-start justify-evenly mx-4">
            <span>{translate("language", l)}</span>
            <ClavaSelect uniqueId={"language"} items={langItems} onChange={onChangeLangCont}
                         transparent
                         value={l} />
          </div>
        </div>
        <div className="flex flex-row items-center justify-end py-2">
          <button
            className="bg-red-10 flex flex-col items-center justify-center p-2 mr-4 rounded-full"
            onClick={onLogout}>
            <LogoutIcon size={24} color={"#FF4949"} />
          </button>
          <div className="flex flex-row items-stretch">
            <div className="flex flex-col items-end justify-evenly mr-4">
              <span className="font-bold">{user.givenName + " " + user.familyName}</span>
              <span
                className="text-primary">{user.scopes.filter(s => s.key !== ScopeEnum.REGISTERED).map(s => showTranslated(s.name, l)).join(", ")}</span>
            </div>
            <div
              className="w-12 h-12 rounded-full overflow-hidden flex flex-col items-center justify-center bg-light-gray dark:bg-light-gray-dark">
              {!!user.photo ? (
                <ClavaImage width={"100%"} image={user.photo} />
              ) : (
                <UserIcon size={32} />
              )}
            </div>
          </div>
        </div>
      </div>
    </RoundedBlock>
  );
};

export default Header;
