import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { Ad, AdPositionEnum, Image } from "../../client/api";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { translate } from "../../config/translator";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { ClavaSelectItems } from "../../config/types";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import ClavaDateInput from "../../components/Atoms/ClavaDateInput";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";
import { sameArray } from "../../config/utils";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";
import FileUploader from "../../components/FileUploader/FileUploader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import MessageBox from "../../components/Atoms/MessageBox";
import { useNavigate } from "react-router";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import { adPositionItems } from "../../config/constants";
import useBoolState from "../../hooks/useBoolState";
import { useAois } from "../../hooks/useAois";
import client from "../../client";
import ClavaSlider from "../../components/Atoms/ClavaSlider";
import LinkGenerator from "./LinkGenerator";

const aoiCheck: AdditionalCheck<Ad, "areasOfInterest"> = sameArray;
const AdvertisingEditForm: React.FC<{ ad: Ad, changes: (ad: Ad) => void }> = ({
                                                                                ad,
                                                                                changes
                                                                              }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(ad,
    ["url", "name", "priority", "position", "paused", "start", "stop", "color"],
    { areasOfInterest: aoiCheck },
    ["name", "color", "url", "position"],
    ["paused"],
    ["priority"],
    ["start", "stop"]
  );
  const [del, toggleDelete] = useBoolState(false);
  const [patchImage, togglePatchImage] = useBoolState(false);
  const { loading, call } = useServer(false);
  const [generateOutUrl, setGenerateOutUrl] = useState(false);
  const originalUrl = useRef<string>();
  const [realUrl, setRealUrl] = useState<string>(ad.url);
  const positionItems = useMemo<ClavaSelectItems[]>(
    () => adPositionItems(l),
    [l]
  );
  useEffect(() => {
    if (!ad.url.startsWith("inapplink")) {
      client().getOut(ad.url).then((target) => {
        originalUrl.current = target;
        setRealUrl(target);
      });
    }
  }, [ad.url]);
  const as = useMemo(() => {
    if (form.position === AdPositionEnum.MATCH_HISTORY_BOTTOM) return 1080 / 120;
    if (form.position === AdPositionEnum.STANDINGS) return 1080 / 120;
    if (form.position === AdPositionEnum.TEAM_OF_THE_WEEK) return 788 / 260;
    return 1080 / 300;
  }, [form.position]);
  const { aoiItems, selectedAois, onChangeAois } = useAois(false, form.areasOfInterest, (val) => {
    form.onChange("areasOfInterest", val);
  });
  const onChangeImage = useCallback((file: Image) => {
    togglePatchImage();
    call("patchAd", [ad.id, {
      imageDesktopId: file.id,
      imageMobileId: file.id
    }], "saveFailed", "saveSuccess").then((res) => {
      form.setFormData(fd => ({
        ...fd,
        imageMobile: res.imageMobile,
        imageDesktop: res.imageDesktop
      }));
      changes(res);
      form.setInitial(res);
    });
  }, [togglePatchImage, call, ad.id, changes, form]);
  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      const url = realUrl !== originalUrl.current ? realUrl : undefined;
      call("patchAd", [ad.id, {
        name: form.name,
        position: form.position,
        start: form.start,
        stop: form.stop,
        paused: form.paused,
        color: form.color,
        url,
        generateOutUrl: !!url && !url.startsWith("inapplink://"),
        priority: form.priority,
        areaOfInterestIds: form.areasOfInterest.map(aoi => aoi.id)
      }]).then((res) => {
        changes(res);
        form.setInitial(res);
      });
    }
  }, [changes, form, call, ad.id, realUrl]);
  const navigator = useNavigate();
  const onDelete = useCallback(() => {
    call("deleteAd", [ad.id], "deleteFailed").then(() => {
      navigator("/advertising");
    });
  }, [ad.id, call, navigator]);
  const toggleGenerateLink = useCallback(() => {
    setGenerateOutUrl(go => !go);
  }, []);

  return (
    <RoundedBlock>
      <ItemBlockHeader image={ad.imageMobile}
                       imageHeader={as} name={ad.name}
                       onDelete={toggleDelete}
                       id={ad.id}
                       onPatchImage={togglePatchImage}>

        <div className="flex flex-row items-center justify-evenly">
          <ClavaButton className="flex-1 mr-2" onClick={toggleGenerateLink} secondary>
            {translate("inAppLink", l)}
          </ClavaButton>
          <ClavaButton onClick={onSave} className="flex-1 ml-2"
                       disabled={form.nothingChanged}>{translate("save", l)}</ClavaButton>
        </div>
      </ItemBlockHeader>
      <LoadingBlock isLoading={loading}>
        <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeName}
                          changed={form.nameChanged}
                          value={form.name}
                          label={translate("name", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={setRealUrl}
                          error={!(realUrl.startsWith("http") || realUrl.startsWith("inapplink"))}
                          changed={realUrl !== originalUrl.current}
                          disabled={!originalUrl.current}
                          value={realUrl}
                          label={translate("url", l)} />
          <ClavaSelect className="flex-1 mx-2" value={form.position}
                       changed={form.positionChanged}
                       items={positionItems}
                       onChange={form.onChangePosition}
                       label={translate("adPosition", l)} uniqueId={"adPosition"} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaDateInput value={form.startDate} onChange={form.onChangeStart}
                          className="flex-1 mx-2"
                          changed={form.startChanged}
                          name={"startDate"}
                          label={translate("startDate", l)} type={"datetime"} />
          <ClavaDateInput value={form.stopDate} onChange={form.onChangeStop}
                          className="flex-1 mx-2"
                          changed={form.stopChanged}
                          name={"endDate"}
                          label={translate("stopDate", l)} type={"datetime"} />
          <ClavaSwitch value={!!form.paused} className="flex-1 mx-2"
                       onChange={form.onChangePaused}
                       changed={form.pausedChanged}
                       label={translate("paused", l)} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSlider onChange={form.onChangePriority} value={form.priority}
                       changed={form.priorityChanged}
                       label={translate("priority", l)} className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeColor} value={form.color ?? ""}
                          changed={form.colorChanged}
                          label={translate("backgroundColor", l)} className="flex-1 mx-2" />
          <ClavaSelectMultiple uniqueId={"aois-ad"} items={aoiItems} onChange={onChangeAois}
                               changed={form.areasOfInterestChanged}
                               label={translate("province", l)} value={selectedAois}
                               className="flex-1 mx-2" />

        </div>
      </LoadingBlock>
      {patchImage && (
        <FileUploader as={as} type={"image"} callback={onChangeImage} close={togglePatchImage} />
      )}

      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": ad.name })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
      {generateOutUrl && (
        <LinkGenerator onSave={setRealUrl} onClose={toggleGenerateLink} />
      )}
    </RoundedBlock>
  );
};

export default AdvertisingEditForm;
