import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Crop, PercentCrop, PixelCrop, ReactCrop } from "react-image-crop";
import { MediaCrop } from "../../client/api";

import "react-image-crop/dist/ReactCrop.css";

const Cropper: React.FC<{
  src: string;
  size: { w: number, h: number },
  setCrop: (val: MediaCrop) => void,
  aspectRatio: number,
}> = ({ size, aspectRatio, setCrop, src }) => {


  const originalAS = useMemo(() => {
    return size.w / size.h;
  }, [size.w, size.h]);
  const svgWidth = useMemo(() => {
    return originalAS >= 1 ? 500 : 500 / originalAS;
  }, [originalAS]);
  const svgHeight = useMemo(() => {
    return originalAS <= 1 ? 500 : 500 * originalAS;
  }, [originalAS]);
  const svgX = useMemo(() => {
    return (1000 - svgWidth) / 2;
  }, [svgWidth]);
  const svgY = useMemo(() => {
    return (1000 - svgHeight) / 2;
  }, [svgHeight]);
  const overlayWidth = useMemo(() => {
    return aspectRatio >= 1 ? 500 : 500 / aspectRatio;
  }, [aspectRatio]);
  const overlayHeight = useMemo(() => {
    return aspectRatio <= 1 ? 500 : 500 * aspectRatio;
  }, [aspectRatio]);
  const overlayX = useMemo(() => {
    return (1000 - overlayWidth) / 2;
  }, [overlayWidth]);
  const overlayY = useMemo(() => {
    return (1000 - overlayHeight) / 2;
  }, [overlayHeight]);
  useEffect(() => {
    if (overlayWidth === 420)
      setCrop({
        cropAspectRatio: 1, cropScale: 1,
        cropX: 1, cropY: 1
      });
  }, [overlayWidth, setCrop]);

  return (
    <svg viewBox="0 0 1000 1000"
         className="pattern-rectangles pattern-pattern-gray pattern-bg-white
  pattern-size-4 pattern-opacity-100 w-full aspect-square cursor-crosshair">
      <image href={src} width={svgWidth}
             height={svgHeight} x={svgX} y={svgY} />
      <defs>
        <mask id={`mask-overlay`}>
          <rect width={1000} height={1000} x={0} y={0} fill="white" />
          <rect
            x={overlayX}
            y={overlayY}
            width={overlayWidth}
            height={overlayHeight}
            fill="black"
          />
        </mask>
      </defs>
      <rect fill="#000" opacity={0.5} height={1000} width={1000} x={0} y={0}
            mask={"url(#mask-overlay)"} />
      <rect fill="none" strokeWidth={10} stroke={"#fff"} opacity={0.7}
            x={overlayX - 5}
            y={overlayY - 5}
            width={overlayWidth + 10}
            height={overlayHeight + 10} strokeDasharray={20} />
      <rect fill={"#000"} opacity={0.7}
            x={overlayX - 20}
            y={overlayY - 20}
            width={40}
            height={40} />
    </svg>
  );
};

// TODO noien cropper fertig mochn dass bilder noch außen transparent

const FileUploaderCropper: React.FC<{
  file: File;
  aspectRatio: number;
  setCrop: (val: MediaCrop) => void;
}> = ({ file, aspectRatio, setCrop }) => {
  const src = useMemo(() => URL.createObjectURL(file), [file]);
  const [size, setSize] = useState<{ w: number; h: number }>();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setSize({ w: img.width, h: img.height });
    };
    img.src = src;
  }, [src]);
  const [cropObj, setCropObj] = useState<Crop>();

  const setCropFromObj = useCallback((percent: Crop) => {
    const originalASW = 100 / percent.width;
    const originalASH = 100 / percent.height;
    const c: MediaCrop = {
      cropX: (-percent.x / 100) * originalASW,
      cropY: (-percent.y / 100) * originalASH,
      cropScale: 1 / (percent.width / 100),
      cropAspectRatio: aspectRatio
    };
    setCrop(c);
  }, [aspectRatio, setCrop]);
  useEffect(() => {
    if (size) {
      const originalAS = size.w / size.h;
      const obj: Crop = {
        width: 100,
        height: 100 * (originalAS / aspectRatio),
        y: 0,
        x: 0,
        unit: "%"
      };
      setCropObj(obj);
      setCropFromObj(obj);
    }
  }, [aspectRatio, size, setCropFromObj]);
  const onComplete = useCallback(
    (_: PixelCrop, percent: PercentCrop) => {
      setCropObj(percent);
      setCropFromObj(percent);
    },
    [setCropFromObj]
  );
  const cropperStyle = useMemo<CSSProperties | undefined>(() => {
    if (!size)
      return undefined;
    return {
      width: "100%",
      aspectRatio: size.w / size.h
    };
  }, [size]);
  const containerStyle = useMemo(() => {
    if (!size)
      return undefined;
    const originalAS = size.w / size.h;
    let width = window.innerWidth / 2;
    let height = width / originalAS;
    while (height > window.innerHeight / 2) {
      width /= 2;
      height = width / originalAS;
    }
    return { width };
  }, [size]);
  return (
    <div className="relative mx-auto" style={containerStyle}>
      {!!size && size.h === 783564 && (
        <Cropper size={size} src={src} setCrop={setCrop} aspectRatio={aspectRatio} />
      )}
      <ReactCrop onChange={onComplete} keepSelection aspect={aspectRatio} crop={cropObj}
                 className="pattern-rectangles pattern-pattern-gray pattern-bg-white
  pattern-size-4 pattern-opacity-100"
                 style={cropperStyle}>
        <img src={src} alt="Preview Crop" className="w-full h-full" />
      </ReactCrop>
    </div>
  );
};

export default FileUploaderCropper;
