import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import TableHeader from "../../components/Table/TableHeader";
import { ClavaContext, SiteBlockerContext } from "../../config/contexts";
import { MatchListElement } from "../../client/api";
import { useNavigate } from "react-router";
import useServer from "../../hooks/useServer";
import { showTranslated, translate } from "../../config/translator";
import { formatDate, getMatchDate, matchLeagueToMatchListElement } from "../../config/utils";
import { IDType, MatchChanges } from "../../config/types";
import Filter, { FilterProp } from "../../components/Views/Filter";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import MatchIcon from "../../components/Icons/MatchIcon";
import Table from "../../components/Table/Table";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import { faChevronLeft, faSave } from "@fortawesome/pro-regular-svg-icons";
import TableRowEditable from "../../components/Table/TableRowEditable";
import client from "../../client";
import { TableEditableRowItem, useEditableList } from "../../hooks/useEditableList";
import moment from "moment/moment";
import BulkActions from "../../components/Table/BulkActions";

const headersEdit: TableHeaderItem[] = [
  {
    label: "id",
    sortable: true,
    key: "id"
  },
  {
    label: "matchDay",
    sortable: true,
    key: "matchDay"
  },
  {
    label: "startDate"
  },
  {
    label: "league"
  },
  {
    sortable: true,
    label: "team1",
    key: "team1"
  },
  {
    sortable: true,
    label: "team2",
    key: "team2"
  }
];

const bulkInitial = {
  startTime: "",
  matchDay: 0
};
const bulkProps: (keyof typeof bulkInitial)[] = [
  "startTime",
  "matchDay"
];
const numberProps: (keyof typeof bulkInitial)[] = [
  "matchDay"
];
const dateProps: (keyof typeof bulkInitial)[] = [
  "startTime"
];
const MatchEditList = () => {
  const { l, filterAoi, sports } = useContext(ClavaContext);
  const { call, loading, status, StatusComponent } = useServer(true);
  const navigator = useNavigate();
  const mapperEdit = useCallback((item: MatchListElement): TableEditableRowItem => {
    return {
      search: `${showTranslated(item.team1.name, l)} ${showTranslated(item.team2.name, l)}`.toLowerCase(),
      row: item.id,
      items: [
        {
          value: item.id.toString(10),
          readonly: true,
          col: 0
        }, {
          value: item.matchDay.toString(10),
          col: 1
        }, {
          col: 2,
          value: formatDate(getMatchDate(item), l, false)
        }, {
          col: 3,
          value: showTranslated(item.league.name, l),
          readonly: true
        }, {
          col: 4,
          value: `${showTranslated(item.team1.name, l)} [${item.team1.id}]`
        }, {
          col: 5,
          value: `${showTranslated(item.team2.name, l)} [${item.team2.id}]`
        }
      ]
    };
  }, [l]);
  const {
    q,
    setQ,
    setItems,
    filtered,
    selected,
    allSelected,
    onCheck,
    onCheckAll,
    items, sthChanges,
    limit, sorted, onSort
  } = useEditableList(mapperEdit, 30);
  const [leagueId, setLeagueId] = useState<IDType>();
  const [aoiId, setAoiId] = useState<string | undefined>(filterAoi);
  const [teamId, setTeamId] = useState<IDType>();
  const [matchDay, setMatchDay] = useState<string>();
  const onSearch = useCallback((off: number, lim: number) => {
    const numM = matchDay && matchDay !== "" ? parseInt(matchDay, 10) : Number.NaN;
    return call("searchMatches", [q.length === 0 ? " " : q, off, lim, aoiId, leagueId, teamId, Number.isNaN(numM) ? undefined : numM]);
  }, [q, call, leagueId, aoiId, teamId, matchDay]);
  const [changes, setChanges] = useState<MatchChanges[]>([]);
  const { block } = useContext(SiteBlockerContext);
  useEffect(() => {
    block(changes.length !== 0);
  }, [changes.length, block]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  const filters = useMemo<FilterProp<"matchDay" | "leagueFilter" | "areaOfInterestFilter" | "teamFilter">[]>(() => {
    return [
      {
        type: "areaOfInterestFilter",
        val: aoiId,
        setVal: setAoiId
      },
      {
        type: "leagueFilter",
        val: leagueId,
        setVal: setLeagueId
      },
      {
        type: "teamFilter",
        val: teamId,
        setVal: setTeamId
      },
      {
        type: "matchDay",
        val: matchDay,
        setVal: setMatchDay
      }
    ];
  }, [aoiId, teamId, leagueId, matchDay]);
  const saveChanges = useCallback(() => {
    call("patchMatches", [changes], "saveFailed", "saveSuccess").then((matches) => {
      matches.forEach(m => sthChanges(matchLeagueToMatchListElement(m)));
      setChanges([]);
    });
  }, [changes, sthChanges, call]);
  const onChangeRow = useCallback((row: number, col: number, val: string) => {
    if (items) {
      const match = items.find(i => i.id === row);
      if (match) {
        const teamIdMatch = val.match(/\[([0-9]+)]/);
        const tId = teamIdMatch ? parseInt(teamIdMatch[0].replace("[", "").replace("]", ""), 10) : undefined;
        const newPatch: MatchChanges["patch"] | undefined = col === 1 ? {
          matchDay: parseInt(val, 10)
        } : col === 2 ? {
          startTime: moment(val, "DD.MM.YYYY HH:mm").toISOString()
        } : col === 4 && !!tId ? {
          toTeam1: tId,
          fromTeam1: match.team1.id
        } : !!tId ? {
          toTeam2: tId,
          fromTeam2: match.team2.id
        } : undefined;
        if (newPatch) {
          setChanges((chs) => {
            const oldChange = chs.findIndex(ch => ch.id === row);
            if (oldChange !== -1) {
              return chs.map((c) => c.id === row ? {
                ...c,
                patch: {
                  ...c.patch,
                  ...newPatch
                }
              } : c);
            } else {
              return chs.concat([{
                id: row,
                patch: newPatch
              }]);
            }
          });
        }
      }
    }
  }, [items]);
  const autocompleteTeams = useCallback((val: string, row: number) => {
    return new Promise<string[]>((resolve) => {
      if (items) {
        const match = items.find(i => i.id === row);
        if (match) {
          const lId = match.league.id;
          client().searchTeams(val, 0, 100, lId).then((res) => {
            resolve(res.map(team => `${showTranslated(team.name, l)} [${team.id}]`));
          });
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }, [items, l]);
  const onSortCont = useCallback((val: string) => {
    onSort(val.replace("id", "0").replace("matchDay", "1").replace("team1", "4").replace("team2", "5"));
  }, [onSort]);
  const sortedCont = useMemo(() => {
    if (!sorted)
      return sorted;
    return sorted.replace("0", "id").replace("1", "matchDay").replace("4", "team1").replace("5", "team2");
  }, [sorted]);
  const onBack = useCallback(() => {
    navigator("/match");
  }, [navigator]);
  const onSaveBulk = useCallback((form: typeof bulkInitial) => {
    call("patchBulk", [selected, {
      startTime: form.startTime.length !== 0 ? form.startTime : undefined,
      matchDay: form.matchDay !== 0 ? form.matchDay : undefined
    }], "saveFailed", "saveSuccess").then((matches) => {
      matches.forEach((m) => {
        sthChanges(matchLeagueToMatchListElement(m));
        selected.forEach((sel) => {
          onCheck(sel);
        });
      });
    });
  }, [selected, onCheck, sthChanges, call]);
  return (
    <React.Fragment>
      <Breadcrumps>
        <ClavaButton onClick={onBack} light className="mr-2">
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {translate("back", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"editMode"} onSearch={setQ} q={q}
                         icon={<MatchIcon />}>
          <ClavaButton onClick={saveChanges} disabled={changes.length === 0} className="mr-2">
            <FontAwesomeIcon icon={faSave} size="lg"
                             className="mr-1" />
            {changes.length !== 0 ? `${changes.length} ${translate("changes", l)} ` : ""}{translate("save", l)}
          </ClavaButton>
          <Filter filter={filters} sports={sports} uniqueId={"match-filters"}
                  className="mr-2" />
        </ListBlockHeader>
        {!!status && !!status.key && StatusComponent}

        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>

          <BulkActions initial={bulkInitial} props={bulkProps} selected={selected}
                       onCancel={onCheckAll} onSave={onSaveBulk} dateProps={dateProps}
                       numberProps={numberProps} />
          <Table>
            <TableHeader sorted={sortedCont} onSort={onSortCont} items={headersEdit}
                         onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map((fil) => {
                const change = changes.find(c => c.id === fil.row);
                let warning: string | undefined;
                if (change) {
                  warning = JSON.stringify(change.patch);
                }
                return (
                  <TableRowEditable row={fil.row} warning={warning}
                                    items={fil.items.map(i => i.col === 4 || i.col === 5 ? {
                                      ...i,
                                      autocomplete: autocompleteTeams
                                    } : i)} onCheck={onCheck} onChange={onChangeRow}
                                    checked={selected.includes(fil.row)}
                                    key={"matchManagement-edit-" + fil.row} />
                );
              }
            )}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
    </React.Fragment>
  );
};

export default MatchEditList;
