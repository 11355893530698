import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBadgeCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { faClose, faWindowRestore } from "@fortawesome/pro-regular-svg-icons";
import ClavaButton from "./ClavaButton";
import ReactDOM from "react-dom";
import Overlay from "../Layout/Overlay";
import { generatePW } from "../../config/utils";


const modalRoot = document.getElementById("root") as HTMLElement;

const MessageBox: React.FC<React.PropsWithChildren<{
  type: "warning" | "info" | "danger" | "success";
  open: boolean;
  toggle: () => void;
  msg: string;
  title?: string;
  btn1Clb: () => void;
  btn1Text: string;
  btn1Disabled?: boolean;
  btn2Clb?: () => void;
  btn2Text?: string;
  btn2Primary?: boolean;
  btn2Disabled?: boolean;
  className?: string;
}>> = ({
         type,
         title,
         open,
         toggle,
         children,
         btn2Clb,
         btn2Disabled, btn1Disabled,
         btn1Clb,
         btn1Text, className,
         btn2Text,
         btn2Primary,
         msg
       }) => {
  const [id, setId] = useState("");
  useEffect(() => {
    setId("modal-" + open + "-" + generatePW(12));
  }, [open]);
  if (!open)
    return null;
  return ReactDOM.createPortal((
    <div className="fixed inset-0 flex items-center justify-center" id={id}>
      <Overlay close={toggle} />
      <div
        className={"max-w-xl border-orange my-2 bg-white dark:bg-bg-dark rounded-xl drop-shadow-lg " + className}>
        {!!title && (
          <div
            className="flex flex-row items-center justify-between mb-2 border-b border-b-light-gray dark:border-b-light-gray-dark">
            <span className="font-semibold text-lg p-4">{title}</span>
            <button className="border-none outline-none p-4" role="button" onClick={() => {
              const box = document.getElementById(id);
              if (box) {
                const myWindow = window.open(location.hostname, "_blank", `width=${box.clientWidth / 2},height=${box.clientHeight / 2}`);
                if (myWindow) {
                  myWindow.addEventListener("load", () => {
                    setTimeout(() => {
                      const root = myWindow.document.getElementById("root");
                      if (root)
                        root.appendChild(box.cloneNode(true));
                    }, 3000);
                  });
                }
              }
            }}>
              <FontAwesomeIcon icon={faWindowRestore} size="lg" />
            </button>
            <button className="border-none outline-none p-4" role="button" onClick={toggle}>
              <FontAwesomeIcon icon={faClose} size="lg" />
            </button>
          </div>
        )}
        <div className="p-4">
          <div className="flex flex-col items-center justify-start mb-2">
            <FontAwesomeIcon
              icon={type === "warning" ? faTriangleExclamation : type === "success" ? faBadgeCheck : type === "danger" ? faCircleExclamation : faCircleInfo}
              size="3x"
              color={type === "warning" ? "#FFAB49" : type === "success" ? "#2FCB80" : type === "danger" ? "#FF7272" : "#5AA0E1"} />
            <p className="mt-2 text-center">{msg}</p>
            {children}
          </div>
          <div
            className={`flex flex-row items-center ${!!btn2Text ? "justify-between" : "justify-center"} mt-2`}>
            {!!btn2Text ? (
              <ClavaButton secondary={!btn2Primary} disabled={btn2Disabled}
                           onClick={btn2Clb ?? toggle} className="mr-2">
                {btn2Text}
              </ClavaButton>
            ) : null}
            <ClavaButton onClick={btn1Clb} disabled={btn1Disabled}>
              {btn1Text}
            </ClavaButton>
          </div>
        </div>
      </div>
    </div>
  ), modalRoot);
};

export default MessageBox;
