/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OutService {
    /**
     * Get Out Target
     * @param url
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getOutTargetOutTargetGet(
        url: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/out/target',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Out Count
     * @param url
     * @param startDate
     * @param endDate
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getOutCountOutCountGet(
        url: string,
        startDate?: (string | null),
        endDate?: (string | null),
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/out/count',
            query: {
                'url': url,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Redirect
     * @param key
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static redirectOutKeyUserIdGet(
        key: string,
        userId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/out/{key}/{user_id}',
            path: {
                'key': key,
                'user_id': userId,
            },
            errors: {
                307: `Successful Response`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Redirect Without User
     * @param key
     * @returns void
     * @throws ApiError
     */
    public static redirectWithoutUserOutKeyGet(
        key: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/out/{key}',
            path: {
                'key': key,
            },
            errors: {
                307: `Successful Response`,
                422: `Validation Error`,
            },
        });
    }
}
