import React from "react";
import inner from "../../assets/images/logo-symbol.png";

const Loading: React.FC<{ fullscreen?: boolean }> = ({ fullscreen }) => (
  <div
    className={"flex flex-row items-center justify-center" + (fullscreen ? " min-h-screen min-w-screen" : "")}>
    <img src={inner} width={fullscreen ? 120 : 42} className="mr-4 animate-bounce"
         style={{ animationDuration: "2s" }}
         alt="Loading Animation inner" />
    <span
      className={fullscreen ? "font-black text-6xl" : "font-semibold mb-3 text-lg"}>Loading...</span>
  </div>
);

export default Loading;
