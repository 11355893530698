import { Action, Dispatch } from "redux";
import * as Sentry from "@sentry/react";
import { ApiError, CancelablePromise } from "../../client/api";
import { ValueStore } from "../constants";
import { AllActions, InitAction, PayloadAction, WithoutResponse } from "./types";
import { TranslatorKeys } from "../../config/translator";

export function fetchError(
  error: ApiError | string,
  actionType: string,
  dispatch: Dispatch<Action>
): void {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("\x1b[31m%s\x1b[0m", `ERROR on ${actionType}`);
    // eslint-disable-next-line no-console
    console.log("\x1b[31m%O\x1b[0m", error);
    if (typeof error !== "string") {
      // eslint-disable-next-line no-console
      console.log("\x1b[31m%O\x1b[0m", error.body);
    }
  } else {
    Sentry.addBreadcrumb({
      type: "error",
      level: "error",
      category: "api",
      data: typeof error === "string" ? { reason: error } : error
    });
  }
  if (typeof error === "string") {
    dispatch({
      type: actionType,
      payload: error
    });
    return;
  }
  // 400 bad request
  // 401 Unauthorized lei wenn token eps fahlt
  // 403 Forbidden
  // 404 Not found
  // 412 condition not supported, request passt obo des dorf man no net tian oder nimmer
  // 413 file too big
  // 415 unsupported media type
  // 422 unprocessable entity ( server error )
  // 451 team do not match, to match
  // 452 player not in team/match
  // 453 match already started
  // 455 duplicate event (force)
  // 459 can not log in, tel not set (wenn login sms bei nicht verknüpften account versucht werd)
  // 460 bei player merge, both players claimed
  // 461 cannot delete, depends on xxx
  // 50x server error
  // @ts-ignore
  const errorKey: TranslatorKeys =
    error.status === 422 ||
    error.status === 459 ||
    error.status === 401 ||
    error.status === 400 ||
    error.status === 403
      ? "credentials_wrong"
      : error.status === 455
        ? "goalReportedTwice"
        : error.status === 500 || error.status === 502 || error.status === 503
          ? "serverError"
          : error.status === 404
            ? "notFound"
            : error.status === 461
              ? "cannotDelete"
              : error.status === 460
                ? "cannotMerge"
                : error.status === 459
                  ? "telNotFound"
                  : error.status === 454
                    ? "tooManySponsors"
                    : "noConnection";

  dispatch({
    type: actionType,
    payload: errorKey
  });
  if (error.status >= 500 && error.status < 505) {
    //     Sentry.captureException(error); TODO
  }
  if (error.status === 401) {
    // TODO dispatch({type: UserActionTypes.UNAUTHORIZED});
  }
}

/**
 * if you do returnObject, it will return an object you passed with an additional response param
 *
 * @param dispatch
 * @param actionSuccess
 * @param actionFail
 * @param actionInit
 * @param clientFunc
 * @param returnArray
 * @param returnObject
 * @param args
 */
export function defaultGet<
  A extends AllActions,
  US extends PayloadAction<A>,
  UE extends PayloadAction<A>,
  UI extends InitAction<A>,
  X extends (
    ...funcArgs: any[]
  ) => CancelablePromise<R extends ValueStore<any> ? R["response"] : any>,
  S extends US["type"],
  E extends UE["type"],
  I extends UI["type"],
  R extends ValueStore<any> | false,
>(
  dispatch: Dispatch<A | any>,
  actionSuccess: S,
  actionFail: E,
  actionInit: I,
  clientFunc: X,
  returnArray: boolean,
  returnObject: WithoutResponse<R>,
  ...args: Parameters<X>
) {
  dispatch({ type: actionInit });
  clientFunc(...args).then(
    response => {
      dispatch({
        type: actionSuccess,
        payload: returnObject
          ? {
            ...returnObject,
            response: returnArray ? [response] : response,
            fetchDate: new Date()
          }
          : returnArray
            ? [response]
            : response
      });
    },
    e => {
      fetchError(e, actionFail, dispatch);
    }
  );
}
