import { useCallback, useMemo, useState } from "react";
import { IDType } from "../config/types";
import { TableCellItem } from "../components/Table/TableCell";

export declare type TableRowItem = {
  id: IDType,
  bg?: string;
  items: TableCellItem[],
  search: string
};

export function useList<T extends Record<string, any>>(mapper: (item: T) => TableRowItem, limit = 100) {
  const [selected, setSelected] = useState<IDType[]>([]);
  const [q, setQ] = useState("");
  const [items, setItems] = useState<T[]>();
  const [sorted, setSorted] = useState<string>();
  const mapped = useMemo(() => {
    if (!items)
      return [];
    return items.map<TableRowItem>(mapper);
  }, [items, mapper]);
  const filteredUnsorted = useMemo(() => {
    const numeric = parseInt(q, 10);
    if (!Number.isNaN(numeric) && !q.match(/[^0-9]+/g)) {
      return mapped.filter(lea => lea.id === numeric);
    }
    const splitted = q.split(" ");
    return mapped.filter(lea => splitted.reduce<boolean>((s, prev) => lea.search.indexOf(prev.toLowerCase()) !== -1 && s, true));
  }, [mapped, q]);
  const allSelected = useMemo(() => {
    return selected.length === filteredUnsorted.length && selected.length !== 0;
  }, [selected.length, filteredUnsorted.length]);
  const onCheckAll = useCallback(() => {
    setSelected(allSelected ? [] : filteredUnsorted.map(f => f.id));
  }, [filteredUnsorted, allSelected]);

  const sthChanges = useCallback((valOrId: T | IDType) => {
    if (typeof valOrId === "number") {
      setItems(itms => itms ? itms.filter(item => item.id !== valOrId) : undefined);
    } else {
      setItems(itms => {
        if (!itms) return undefined;
        let found = false;
        const retval = itms.map(item => {
          if (item.id === valOrId.id) {
            found = true;
            return valOrId;
          }
          return item;
        });
        if (found)
          return retval;
        return [valOrId].concat(retval);
      });
    }
  }, []);
  const shouldSearch = useMemo(() => {
    return !items || items.length === 0 || items.length === limit;
  }, [items, limit]);
  const onCheck = useCallback((id: IDType) => {
    setSelected(s => s.includes(id) ? s.filter(sel => sel !== id) : s.concat(id));
  }, []);
  const filtered = useMemo(() => {
    if (!sorted)
      return filteredUnsorted;
    const key = sorted.split("_")[0];
    const desc = sorted.split("_")[1] === "desc";
    return filteredUnsorted.sort((a, b) => {
      const aTem = a.items.find(elem => elem.key === key)?.label;
      const bTem = b.items.find(elem => elem.key === key)?.label;
      if (aTem && bTem) {
        if (typeof aTem === "number" && typeof bTem === "number") {
          if (desc)
            return bTem - aTem;
          return aTem - bTem;
        }
        if (desc)
          return bTem.toString(10).localeCompare(aTem.toString(10));
        return aTem.toString(10).localeCompare(bTem.toString(10));
      }
      return 0;
    });
  }, [filteredUnsorted, sorted]);
  const onSort = useCallback((val: string) => {
    if (!sorted)
      setSorted(val + "_asc");
    else {
      if (sorted.indexOf(val) === -1) {
        setSorted(val + "_asc");
      } else {
        if (sorted.indexOf("asc") === -1) {
          setSorted(val + "_asc");
        } else {
          setSorted(val + "_desc");
        }
      }
    }
  }, [sorted]);
  return {
    sorted,
    onSort,
    onCheckAll,
    onCheck,
    allSelected,
    filtered,
    mapped,
    items,
    setItems,
    q, setQ,
    selected,
    sthChanges,
    shouldSearch,
    limit
  };
}
