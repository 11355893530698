import React, { useCallback, useContext, useMemo, useState } from "react";
import { ClavaContext, ClavaRootContext } from "../../config/contexts";
import RoundedBlock from "../Atoms/RoundedBlock";
import ClickableIcon from "../Icons/ClickableIcon";
import { faChevronSquareLeft, faChevronSquareRight } from "@fortawesome/pro-solid-svg-icons";
import logo from "../../assets/images/logo-app.png";
import logoLight from "../../assets/images/logo-app-white.png";
import logoSymbol from "../../assets/images/logo-symbol.png";
import { routes } from "../../config/routes";
import { isAdmin } from "../../config/utils";
import SidebarItem from "./SidebarItem";
import { useLocation } from "react-router";
import { ClavaRoute } from "../../config/types";
import { ScopeEnum } from "../../client/api";
import { translate } from "../../config/translator";
import { Link } from "react-router-dom";


function mapActive(path: string, route: ClavaRoute): ClavaRoute {
  if (path === "" || path === "/") {
    return {
      ...route,
      active: route.path === "/dashboard"
    };
  }
  return {
    ...route,
    active: route.path.startsWith(path)
  };

}

function filterRoutes(scopes: ScopeEnum[], route: ClavaRoute): boolean {
  return !!route.rights.find(r => scopes.includes(r));

}

const Sidebar: React.FC = () => {
  const { theme } = useContext(ClavaRootContext);
  const { user, l } = useContext(ClavaContext);
  const [open, setOpen] = useState(true);
  const finalLogo = useMemo(() => {
    return open ? (theme === "light" ? logoLight : logo) : logoSymbol;
  }, [open, theme]);
  const toggleOpen = useCallback(() => {
    setOpen(o => !o);
  }, []);
  const currentPath = useLocation();
  const items = useMemo(() => {
    if (isAdmin(user))
      return routes.map(r => typeof r === "string" ? r : mapActive(currentPath.pathname, r));
    return routes.filter(r => typeof r === "string" ? true : filterRoutes(user.scopes.map(s => s.key.toUpperCase() as unknown as ScopeEnum), r)).map(r => typeof r === "string" ? r : mapActive(currentPath.pathname, r));
  }, [user, currentPath]);
  return (
    <RoundedBlock
      className={"transition-all mx-1 " + (open ? "max-w-[220px] min-w-[220px]" : "max-w-[56px] px-2")}>
      <div
        className={"flex items-center justify-between mb-4 " + (open ? "flex-row" : "flex-col")}>
        <Link to={"/"} className="my-2"><img className={open ? "w-[140px]" : "w-[40px]"}
                                             src={finalLogo}
                                             alt={"Logo"} /></Link>
        <ClickableIcon icon={open ? faChevronSquareLeft : faChevronSquareRight}
                       onClick={toggleOpen} size="lg" />
      </div>
      <div className="sticky top-0">
        {open && (
          <div className="px-2 pt-2">
        <span
          className="opacity-70 font-medium">{translate("menu", l)}</span>
          </div>
        )}
        <ul className="list-none">
          {items.map((item, i) => typeof item === "string" ?
            <div className={item} key={item + "-" + i}></div> : (
              <SidebarItem text={item.name} open={open} path={item.path} key={item.path}
                           active={item.active} />
            ))}
        </ul>
      </div>
    </RoundedBlock>
  );
};

export default Sidebar;
