import React, { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";

export type ClavaCheckboxProps = {
  disabled?: boolean;
  checked?: boolean;
  changed?: boolean;
  error?: boolean;
  vertical?: boolean;
  className?: string;
  onClick: (checked: boolean) => void;
  label?: string
};

const ClavaCheckbox: React.FC<ClavaCheckboxProps> = ({
                                                       onClick,
                                                       vertical,
                                                       checked,
                                                       changed,
                                                       error,
                                                       className,
                                                       label,
                                                       disabled
                                                     }) => {
  const inputClass = useMemo(() => {
    let style = "hover:text-primary-80";
    if (disabled) {
      style += " text-input-border dark:text-input-border-dark";
    } else if (error) {
      style +=
        " text-red";
    } else if (checked) {
      style +=
        " text-primary";
    }
    return style;
  }, [disabled, error, checked]);
  const onPress = useCallback(() => {
    onClick(!checked);
  }, [onClick, checked]);
  return (
    <button className={"relative outline-0" + className} onClick={onPress}>
      <div
        className={!vertical ? "flex flex-row items-center justify-between" : "flex flex-col items-start justify-start"}>
        {!!label && (
          <span
            className={`font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
          >
          {label}
            {changed && (
              <span
                className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
        )}
        <FontAwesomeIcon icon={checked ? faCircleCheck : faCircle} size="xl"
                         className={inputClass} />
      </div>
    </button>
  );
};
export default ClavaCheckbox;
