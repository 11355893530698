import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

const ClavaSlider: React.FC<{
  disabled?: boolean;
  label?: string;
  changed?: boolean;
  error?: boolean;
  className?: string;
  value?: number;
  onChange: (val: number) => void;
}> = ({
        disabled,
        label, className, changed,
        value,
        onChange,
        error
      }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [val, setVal] = useState(value);
  const sliderClass = useMemo(() => {
    let style =
      "w-full cursor-pointer p-0 outline-0 rounded-xl active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark";

    if (disabled) {
      style += " border-input-border";
    } else {
      style +=
        " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark";
    }
    if (error) {
      style += " !border-red";
    }
    return style;
  }, [disabled, error]);
  const moving = useRef(false);
  const onMouseDown = useCallback((e: React.MouseEvent) => {
    moving.current = true;
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const xSlider = e.clientX - rect.x;
      const newVal = Math.max(Math.min(100, Math.floor(xSlider / (rect.width / 100))), 0);
      setVal(newVal);
    }
  }, []);
  const onMouseMove = useCallback((e: MouseEvent) => {
    if (moving.current && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const xSlider = e.clientX - rect.x;
      const newVal = Math.max(Math.min(100, Math.floor(xSlider / (rect.width / 100))), 0);
      setVal(newVal);
    }
  }, []);
  const onMouseUp = useCallback((e: MouseEvent) => {
    if (moving.current) {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const xSlider = e.clientX - rect.x;
        const newVal = Math.max(Math.min(100, Math.floor(xSlider / (rect.width / 100))), 0);
        setVal(newVal);
        onChange(newVal);
      }
      moving.current = false;
    }
  }, [onChange]);
  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseUp, onMouseMove]);
  return (
    <div className={"relative flex flex-col items-start justify-stretch w-full " + className}>
      {!!label && (
        <span
          className={`font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
      )}
      <div className={sliderClass} onMouseDown={onMouseDown} ref={sliderRef}>
        <div
          className={`${sliderClass} py-2 bg-primary select-none text-center hover:bg-primary active:bg-primary focus:bg-primary focus:dark:bg-primary active:dark:bg-primary`}
          style={{ width: `${val}%` }}
        >
          <span className="text-black text-center font-bold">{val + "%"}</span>
        </div>
      </div>
    </div>
  );
};

export default ClavaSlider;
