import React, { useCallback, useMemo } from "react";
import { IDType } from "../../config/types";
import TableCell, { TableCellItem } from "./TableCell";


const TableRow: React.FC<{
  id: IDType,
  items: TableCellItem[],
  bg?: string;
  onCheck: (id: IDType) => void,
  checked: boolean
}> = ({ bg, items, id, onCheck, checked }) => {
  const onCheckCont = useCallback(() => {
    onCheck(id);
  }, [id, onCheck]);
  const style = useMemo(() => {
    if (bg)
      return {
        backgroundColor: bg
      };
    return undefined;
  }, [bg]);
  return (
    <tr className="even:bg-input-bg dark:even:bg-input-bg-dark">
      <td className="p-2 text-center" style={style}>
        <input type="checkbox" onChange={onCheckCont} checked={checked} />
      </td>
      {items.map((it, i) => (
        <TableCell label={it.label} buttons={it.buttons} centered={it.centered} pill={it.pill}
                   icon={it.icon} iconCircle={it.iconCircle}
                   key={"tr-" + id + "-" + it.label + "-" + i}
                   myKey={"tr-" + id + "-" + it.label + "-" + i} />
      ))}
    </tr>
  );
};

export default TableRow;
