import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { Image as UserImage, MediaCrop } from "../../client/api";
import ClavaImage from "../Atoms/ClavaImage";
import ClavaButton from "../Atoms/ClavaButton";
import { CloudflareImage } from "../../config/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { translate } from "../../config/translator";
import { uploadCloudflare } from "../../config/mediaUtils";
import client from "../../client";

const FileUploaderPreview: React.FC<{
  file: File,
  crop: MediaCrop | undefined,
  toggleCropper: () => void,
  setWantResize: React.Dispatch<React.SetStateAction<boolean>>,
  callback: (img: CloudflareImage) => void
}> = ({
        file,
        crop, setWantResize, toggleCropper, callback
      }) => {
  const { l } = useContext(ClavaContext);
  const [size, setSize] = useState<{ w: number; h: number }>();
  const src = useMemo(() => URL.createObjectURL(file), [file]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState<CloudflareImage>();
  const [error, setError] = useState<string>();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setSize({ w: img.width, h: img.height });
    };
    img.src = src;
  }, [src]);
  const previewFile = useMemo<UserImage | undefined>(() => {
    if (size && crop) {
      return {
        url: src,
        uploadDate: "",
        id: "asf",
        aspectRatio: size.w / size.h,
        ...crop
      };
    }
    return undefined;
  }, [crop, size, src]);

  const onStartUpload = useCallback((otherFile?: File) => {
    if (size && crop) {
      setIsUploading(true);
      toggleCropper();
      setProgress(0);
      uploadCloudflare({
        file: otherFile ? otherFile : file,
        as: size.w / size.h,
        url: src,
        filename: otherFile ? otherFile.name : file.name
      }, crop, (sent, total) => {
        setProgress(sent / total * 100);
      }).then((res) => {
        setUploaded(res);
      }, (err) => {
        if (typeof err === "string" && err === "error")
          setError(translate("uploadFailed", l));
        else if (typeof err === "string" && err.startsWith("error_length")) {
          setError(translate("imageTooBig", l));
        } else if (typeof err === "object" && "errors" in err) {
          // @ts-ignore
          setError(err.errors.map(e => e.message).join(", "));
        } else {
          setError(translate("uploadFailed", l));
        }
      }).finally(() => {
        setIsUploading(false);
      });
    }
  }, [l, file, crop, size, src, toggleCropper]);
  const progressStyle = useMemo<CSSProperties>(() => {
    return {
      width: `${progress}%`
    };
  }, [progress]);
  const onDelete = useCallback(() => {
    setUploaded(undefined);
    toggleCropper();
    if (uploaded) {
      if (uploaded.path.includes("video.mdp")) {
        client().deleteVideoCloudflare(uploaded.id).then(() => {
          toggleCropper();
        });
      } else {
        client().deleteImageCloudflare(uploaded.id).then(() => {
          toggleCropper();
        });
      }
    }
  }, [uploaded, toggleCropper]);
  const imageStyle = useMemo(() => {
    if (!crop || !crop.cropAspectRatio) {
      return { width: "100%" };
    }
    if (crop.cropAspectRatio > 3) {
      return { width: "100%" };
    }
    return {
      width: `${crop.cropAspectRatio * 25}%`
    };
  }, [crop]);
  const onSave = useCallback(() => {
    if (uploaded) {
      callback(uploaded);
    }
  }, [uploaded, callback]);
  const onSmallerStartUpload = useCallback(() => {
    setWantResize(true);
  }, [setWantResize]);
  if (!previewFile)
    return null;
  return (
    <div className="flex flex-row items-center justify-start">
      {!!error && (
        <div className="flex flex-col items-center justify-start">
          <span className="text-red font-semibold mb-4 block">{error}</span>
        </div>
      )}
      <div className="flex-1 relative mr-4 flex flex-row items-center justify-center">
        <div style={imageStyle} className="pattern-rectangles pattern-pattern-gray pattern-bg-white
  pattern-size-4 pattern-opacity-100">
          <ClavaImage width={"100%"} image={previewFile} />
        </div>
      </div>
      <div className="flex-1">
        {isUploading ? (
          <div
            className="w-full relative h-6 rounded-lg bg-light-gray dark:bg-light-gray-dark flex flex-col items-center justify-center">
            <div
              className="absolute top-0 left-0 h-6 rounded-lg bg-primary flex flex-row items-center justify-center overflow-hidden"
              style={progressStyle}>
            </div>
            <span
              className="text-black dark:text-white absolute">{(progress.toFixed(1)) + " %"}</span>
          </div>
        ) : !!uploaded ? (
          <div className="flex flex-row items-center justify-start">
            <span className="italic mr-4">{uploaded.id}</span>
            <ClavaButton onClick={onDelete} danger><FontAwesomeIcon
              icon={faTrash} /></ClavaButton>
            <ClavaButton onClick={onSave}><FontAwesomeIcon
              icon={faCheck} color="#fff" /></ClavaButton>
          </div>
        ) : !error ? (
          <ClavaButton onClick={onStartUpload}>{translate("save", l)}</ClavaButton>
        ) : (
          <ClavaButton onClick={onSmallerStartUpload}>{translate("makeSmaller", l)}</ClavaButton>
        )}
      </div>
    </div>
  );
};

export default FileUploaderPreview;
